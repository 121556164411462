<template>
    <v-list>
        <v-list top>
            <template v-if="paymentData">
                <v-list-item class="pb-0">
                    <v-list-item-content>
                        <div class="minor mb-2">
                            <template v-if="paymentData.inform_time">
                                <span>{{ $t('แจ้งชำระเมื่อ') }} {{ clock.diff_now_text(paymentData.inform_time) }}</span> ·
                                <span class="d-inline-block minor">{{ clock.show_datetime(paymentData.inform_time) }}</span>
                            </template>
                            <template v-else-if="paymentData.payment_time">
                                <span>ชำระเมื่อ {{ clock.diff_text(clock.now_datetime(), paymentData.payment_time, 2) }}</span> ·
                                <span class="d-inline-block minor">{{ clock.show_datetime(paymentData.payment_time) }}</span>
                            </template>
                        </div>
                    </v-list-item-content>
                    <v-list-item-action>
                        <div class="minor text-right mb-2 fullOpacity chip_container">
                            <v-chip v-if="paymentData.status === 'accept'"
                                    small
                                    color="light-green lighten-5"
                                    text-color="success">
                                <v-icon x-small
                                        class="mr-1">
                                    mdi-check-circle
                                </v-icon>
                                {{ $t('ยืนยันแล้ว') }}
                            </v-chip>
                            <v-chip v-else
                                    small
                                    color="orange lighten-5"
                                    text-color="warning">
                                <v-icon x-small
                                        class="mr-1">
                                    mdi-clock-time-nine-outline
                                </v-icon>
                                {{ $t('รอยืนยัน') }}
                            </v-chip>
                        </div>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <template v-if="paymentData.gateway">
                            <v-list-item-title>
                                {{ paymentData.gateway.gateway_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <div class="d-inline-block">{{ paymentData.gateway.name }}</div>
                                ·
                                <div class="d-inline-block">{{ paymentData.gateway.number }}</div>
                            </v-list-item-subtitle>
                        </template>
                        <!-- กรณี POS หรือ JSL จะไม่มี gateway -->
                        <template v-else>
                            <v-list-item-title>
                                <template v-if="orderData.channel && (orderData.channel === 'Manager_iOS' || orderData.channel === 'Manager_Android') ">
                                    {{ $t('ชำระผ่านหน้าร้าน') }} [POS]
                                </template>
                                <template v-else-if="orderData.channel">
                                    {{ orderData.channel }}
                                </template>
                                <template v-else>
                                    {{ $t('ไม่มีข้อมูลช่องทางชำระ') }}
                                </template>
                            </v-list-item-title>
                        </template>
                    </v-list-item-content>
                    <v-list-item-action>
                        <div class=""><span class="baht">฿</span>{{ number_format(paymentData.amount,2) }}
                        </div>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="paymentData.file">
                    <v-sheet width="100%"
                             class="fill-height rounded overflow-hidden grey lighten-5">
                        <v-img contain
                             :src="paymentData.file"
                             max-height="400"></v-img>
                    </v-sheet>
                </v-list-item>
                <v-list-item v-if="(!orderData.acceptpayment_time) && parseFloat(orderData.price.pay) != parseFloat(paymentData.amount)">
                    <v-alert text
                             type="info"
                             class="mb-2">
                        <span class="small">
                            {{ $t('คุณแจ้งชำระเงินในจำนวนเงินที่ไม่เท่ากันกับราคาสุทธิของการสั่งซื้อครั้งนี้ หากคุณมั่นใจว่าถูกต้องแล้ว กรุณารอร้านค้าตรวจสอบ') }}
                        </span>
                    </v-alert>
                </v-list-item>
            </template>
            <v-list-item v-else-if="isProtectedByLnwPay">
                <template v-if="orderData.informpayment_time">
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('ระบบได้รับข้อมูลชำระเงินแล้ว') }}</v-list-item-title>
                        <v-list-item-subtitle>
                            <span>{{ $t('หากยืนยันยอดเรียบร้อย รายการสั่งซื้อนี้จะได้รับความคุ้มครองโดย') }}</span>
                            <img style="height: 15px; vertical-align: text-top; margin: 0"
                                 alt=""
                                 src="@/assets/lnwpay_logo_gradient.png"/>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <div class="minor text-right mb-2 fullOpacity chip_container">
                            <v-chip outlined
                                    small
                                    color="warning">
                                <v-icon x-small
                                        class="mr-1">
                                    mdi-clock-time-ten-outline
                                </v-icon>
                                {{ $t('รอยืนยัน') }}
                            </v-chip>
                        </div>
                    </v-list-item-action>
                </template>
                <template v-else>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('ไม่มีข้อมูลชำระเงิน') }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ $t('รายการสั่งซื้อนี้ยังไม่มีการชำระเงินที่ตรวจสอบได้') }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </v-list-item>
            <v-list-item v-else
                         style="min-height: 0">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ $t('ไม่มีข้อมูลชำระเงิน') }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ $t('รายการสั่งซื้อนี้ยังไม่มีการชำระเงินที่ตรวจสอบได้') }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-list v-if="canReInformPayment">
            <v-list-item>
                <v-list-item-content>
                    <div class="just_flex flex_wrap" style="gap: 10px;">
                        <div>
                            <v-dialog v-if="!orderData.lnwpay_flag"
                                      v-model="cancelDialog"
                                      width="500">
                                <template v-slot:activator="{ on, attrs }">
                                    <!-- ตอนนี้ LnwPay ไม่สามารถยกเลิกแจ้งโอนเงินได้ -->
                                    <v-btn elevation="0"
                                           large
                                           v-bind="attrs"
                                           v-on="on">{{ $t('ยกเลิกแจ้งโอนนี้') }}
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title class="headline">
                                        {{ $t('ยกเลิกการแจ้งโอนครั้งนี้') }}
                                    </v-card-title>
                                    <v-card-text>{{ $t('หากคุณต้องการแจ้งชำระเงินใหม่อีกครั้ง กรุณากดแจ้งชำระเงินใหม่แทนการยกเลิกการแจ้งโอนครั้งนี้ เนื่องจากการยกเลิกการแจ้งโอนอาจทำให้รายการสั่งซื้อนี้หมดอายุได้ทันที') }}</v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="gray darken-1"
                                               text
                                               @click="cancelDialog = false">
                                            {{ $t('ปิด') }}
                                        </v-btn>
                                        <v-btn color="red darken-1"
                                               text
                                               @click="cancelInformPayment(paymentData);">
                                            {{ $t('ยกเลิกการแจ้งโอน') }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </div>
                        <div>
                            <!-- ปุ่มแจ้งโอนเงิน พร้อม dialog -->
                            <inform-payment-dialog>
                                <template v-slot:activator="{on,attrs}">
                                    <v-btn elevation="0"
                                           outlined
                                           large
                                           color="primary"
                                           v-bind="attrs"
                                           v-on="on">{{ $t('แจ้งชำระเงินใหม่อีกครั้ง') }}
                                    </v-btn>
                                </template>
                            </inform-payment-dialog>
                        </div>
                    </div>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-list>
</template>
<script>
import {mapMutations, mapActions, mapGetters} from "vuex";
import {clock} from "@/libraries/DateTime.js";
import InformPaymentDialog from "@/components/Order/components/PaymentMethod/InformPaymentDialog.vue";
import Helper from "@/libraries/Helper";

export default {
    name: "ShowPaymentInform",
    components: {
        InformPaymentDialog,
    },
    data() {
        return {cancelDialog: false}
    },
    props: {
        isExpanded: {
            type: Boolean,
            required: true,
        },
        payment_informs: {
            type: Array,
            required: true,
        },
        payment_completed: {
            type: Object
        },
        // สามารถแจ้งชำระเงินใหม่อีกครั้งได้หรือเปล่า
        canReInformPayment: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            orderData: 'order/order',
            isProtectedByLnwPay: 'order/isProtectedByLnwPay',
        }),
        paymentData() {
            if (this.payment_completed) {
                return this.payment_completed;
            }
            if (this.payment_informs) {
                return this.payment_informs[ this.payment_informs.length - 1];
            }
            return false;
        },
        clock() {
            return clock;
        }
    },
    methods: {
        ...mapActions({
            expandStep: 'order/expandStep',
            actionCancelInformPayment: 'order/cancelInformPayment',
        }),
        ...mapMutations({
            mutationSetForm: 'order/setForm',
        }),
        cancelInformPayment(paymentData) {
            this.cancelDialog = false;
            this.actionCancelInformPayment({payment_id: paymentData.id});
        },
        number_format(num, decimal) {
            return Helper.number_format(num, decimal);
        },
    },
}
</script>
<style scoped></style>