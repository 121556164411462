<script>
    export default {
        name: "MixinsPaymentComponent",
        props: {
            paymentMethod: {
                type: String,
                required: true,
            },
            paymentMethods: {
                type: Array,
                required: true,
            },
            paymentProfiles: {
                type: Array,
                required: true,
            },
            value: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
        computed: {
            _selectedMethod() {
                if (this.value && this.value.selectedMethod)
                    return this.value.selectedMethod;
                else
                    return '';
            },
            selectedProvider: {
                get: function () {
                    if (
                        this.value
                        && this.value[this.paymentMethod]
                        && this.value[this.paymentMethod].provider_key
                    )
                        return this.value[this.paymentMethod].provider_key;
                    return null;
                },
                set: function (newValue) {
                    let input = Object.assign({}, this.value);
                    input[this.paymentMethod] = {
                        provider_key: newValue,
                        ready: true,
                    };
                    this.$emit('input', input);
                },
            },
            isSelected() {
                return this.paymentMethod === this._selectedMethod;
            },
            myPaymentMethod() {
                for (let i in this.paymentMethods) {
                    if (this.paymentMethods.hasOwnProperty(i)) {
                        if (this.paymentMethod === this.paymentMethods[i].key)
                            return this.paymentMethods[i];
                    }
                }
                return false;
            },
            providers() {
                return this.myPaymentMethod.providers;
            },
        },
        methods : {
            checkValue(newValue) {
                if (newValue
                    && newValue[this.paymentMethod]
                    && newValue[this.paymentMethod].provider_key
                    && !newValue[this.paymentMethod].ready) {
                    const inputValue = Object.assign({}, newValue);
                    if (newValue[this.paymentMethod].provider_key !== 'kplus') {
                        inputValue[this.paymentMethod].ready = true;
                        this.$emit('input', inputValue);
                    }
                }
            }
        },
        mounted(){
            // จำเป็นต้องทำแบบ Watch value ด้วย
            this.checkValue(this.value);
        },
        watch: {
            // เนื่องจากปกติ ช่องทางพวกนี้ พอเลือก provider แล้ว ก็คือ ready เลย
            // ซึ่งปกติ เราจะทำ ready:=true ใน computed -> selectedProvider -> set
            // แต่ทีนี้มันมีกรณีที่จะต้อง เลือก provider มาจากข้างบนด้วย | ref: 991bfef0-15c7-46b5-9c51-b76777dad225
            value(newValue) {
                this.checkValue(newValue);
            },
        },
    }
</script>