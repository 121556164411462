<template>
    <div v-if="canRequestTax">
        <!-- ถ้าช่องทาง -->
        <template v-if="isExpanded">
            <!-- เคยติ๊กว่าจะขอใบกำกับ หรือ สามารถแก้ไขได้อยู่ -->
            <v-card v-if="receiptCheckboxChecked || canEditReceiptContact"
                    :outlined="canEditReceiptContact"
                    :flat="!canEditReceiptContact"
                    :class="(canEditReceiptContact)?'mb-4':''">
                <div class="py-4 px-4 pr-5" v-if="canEditReceiptContact">
                    <v-checkbox v-model="receiptCheckboxChecked"
                                :disabled="!canEditReceiptContact"
                                hide-details
                                style="font-size: 1rem">
                        <template v-slot:label>
                            <div class="just_flex w-100">
                                <div>
                                    <span class="mr-2">{{ (hasVat) ? $t('ต้องการใบกำกับภาษี') : $t('ต้องการใบเสร็จรับเงิน') }}</span>
                                    <v-chip color="primary"
                                            small
                                            v-if="orderData.is_etax_enabled || shop.has_own_etax">
                                        <template v-if="hasVat">
                                            {{ $t('e-tax Invoice') }}
                                        </template>
                                        <template v-else>
                                            {{ $t('e-Receipt') }}
                                        </template>
                                    </v-chip>
                                    <template v-else>
                                        <div class="opacity50 d-inline-block">
                                            ({{ $t('แนบมาพร้อมพัสดุ') }})
                                        </div>
                                    </template>
                                </div>
                                <div v-if="orderData.is_etax_enabled || shop.has_own_etax">
                                    <img style="width: 40px;" :src="require('@/assets/payment/etax.png')"
                                         alt="e-tax invoice by LnwShop">
                                </div>
                            </div>
                        </template>
                    </v-checkbox>
                </div>
                <v-scroll-y-transition hide-on-leave>
                    <div v-if="displayCurrentReceiptContact" class="pt-4">
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-list-item-content>
                                <div class="minor my-4" v-if="!canEditReceiptContact">
                                    {{ (hasVat)?$t('ข้อมูลสำหรับออกใบกำกับภาษี'):$t('ข้อมูลสำหรับออกใบเสร็จรับเงิน') }}
                                </div>
                                <v-list-item-title style="white-space: normal">
                                    <div class="d-inline-block mr-2"
                                         style="font-weight: bold">{{ receipt.name }}
                                    </div>
                                    <div class="d-inline-block"
                                         v-if="receipt.corporate_flag">({{ $t('สาขา') }} {{ receipt.branch_id }})
                                    </div>
                                </v-list-item-title>
                                <v-list-item-title style="font-weight: normal">{{ taxFormat(receipt.tax_id) }}</v-list-item-title>
                                <v-list-item-subtitle>{{ receiptAddressFormat(receipt) }}</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="receipt.email">{{ receipt.email }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="canEditReceiptContact">
                                <template v-if="form_receipt_contact_id">
                                    <a class="primary--text font-weight-normal"
                                       @click="openTaxDialog"
                                       style="line-height: 1.5;">{{ $t('เปลี่ยน') }}</a>
                                </template>
                                <template v-else>
                                    <a class="font-weight-normal"
                                       @click="editTaxAddress(false)"
                                       style="line-height: 1.5;">{{ $t('แก้ไข') }}</a>
                                </template>
                            </v-list-item-action>
                        </v-list-item>
                    </div>
                    <template v-else-if="preferReceiptButNotSelectContact && !taxDialog">
                        <v-alert dense
                                 text
                                 type="warning"
                                 class="mx-6">
                            <div class="just_flex">
                                <span>
                                    {{ (hasVat)?$t('กรุณาเลือกที่อยู่สำหรับการออกใบกำกับภาษี'):$t('กรุณาเลือกที่อยู่สำหรับการออกใบเสร็จรับเงิน') }}
                                </span>
                                <v-btn color="warning"
                                       large
                                       @click="openTaxDialog">{{ $t('เลือก') }}
                                </v-btn>
                            </div>
                        </v-alert>
                    </template>
                </v-scroll-y-transition>
            </v-card>
            <component :is="isDesktop?'VDialog':'VBottomSheet'"
                       v-model="taxDialog"
                       :max-width="isDesktop?'600px':''"
                       :transition="isDesktop?'dialog-transition':'dialog-bottom-transition'"
                       scrollable
                       :persistent="true"
                       class="px-4">
                <v-overlay :value="overlay"
                           absolute="absolute"
                           opacity="0.1"
                           z-index="999">
                    <v-progress-circular indeterminate
                                         size="64"></v-progress-circular>
                </v-overlay>
                <template v-slot:activator="{on,attrs}">
                    <slot name="activator"
                          :on="on"
                          :attr="attrs"></slot>
                </template>
                <!-- dialog ที่อยู่ใบกำกับ -->
                <v-card>
                    <v-card-title class="just_flex">
                        <div>{{ (hasVat)?$t('ข้อมูลสำหรับออกใบกำกับภาษี'):$t('ข้อมูลสำหรับออกใบเสร็จรับเงิน') }}</div>
                        <v-btn icon
                               @click="closeTaxDialog">
                            <v-icon color="primary">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text ref="taxScroll"
                                 style="height: 600px; max-height: 80vh; background-color: #fafafa; padding-top: 20px">
                        <template v-if="allMoreThanPageLimit && !isAddressEditorFormShow">
                            <v-text-field class="mt-3"
                                          clearable
                                          v-disabled-icon-focus
                                          outlined
                                          v-model.trim="search_textbox"
                                          @keypress.enter="searchAddressesEnter()">
                                <template v-slot:label>
                                    {{ $t('ค้นหาที่อยู่ ด้วยชื่อ เบอร์โทร หรืออีเมล') }}
                                </template>
                                <v-btn color="primary"
                                       style="margin-top: -18px !important;"
                                       x-large
                                       @click="searchAddressesEnter()"
                                       slot="append-outer">
                                    {{ $t('ค้นหา') }}
                                </v-btn>
                            </v-text-field>
                        </template>
                        <!-- แสดง list tax contact มาให้เลือก -->
                        <v-scroll-y-transition>
                            <div v-if="!isAddressEditorFormShow">
                                <v-card class="mx-auto"
                                        outlined>
                                    <v-list-item-group top
                                                       style="background-color: white;">
                                        <v-radio-group hide-details="auto"
                                                       v-model="form_receipt_contact_id">
                                            <div class="w-100"
                                                 v-for="index in sortedTaxAddresses"
                                                 :key="index">
                                                <label>
                                                    <v-list-item>
                                                        <v-list-item-action>
                                                            <v-radio color="primary"
                                                                     :value="index"></v-radio>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                <div style="white-space: normal">
                                                                    <div class="d-inline-block mr-2"
                                                                         style="font-weight: bold">{{ taxAddresses[index].name }}
                                                                    </div>
                                                                    <div class="d-inline-block"
                                                                         v-if="taxAddresses[index].corporate_flag">(สาขา {{ taxAddresses[index].branch_id }})
                                                                    </div>
                                                                </div>
                                                            </v-list-item-title>
                                                            <v-list-item-title>{{ taxFormat(taxAddresses[index].tax_id) }}</v-list-item-title>
                                                            <v-list-item-subtitle>{{ receiptAddressFormat(taxAddresses[index]) }}</v-list-item-subtitle>
                                                            <v-list-item-subtitle v-if="taxAddresses[index].email">{{ taxAddresses[index].email }}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <a class="font-weight-normal"
                                                               @click="editTaxAddress(index)"
                                                               style="line-height: 1.5;">{{ $t('แก้ไข') }}</a>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </label>
                                                <v-divider></v-divider>
                                            </div>
                                            <div class="w-100">
                                                <label class="d-block">
                                                    <v-list-item @click="addNewAddress()">
                                                        <v-list-item-action>
                                                            <v-radio color="primary"
                                                                     :value="-1"></v-radio>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title style="margin-bottom: 0">
                                                                {{ $t('เพิ่ม') }}{{ ($i18n.locale == 'th') ? "" : " " }}{{ (hasVat)?$t('ข้อมูลสำหรับออกใบกำกับภาษี'):$t('ข้อมูลสำหรับออกใบเสร็จรับเงิน') }}
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-icon>mdi-chevron-right</v-icon>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </label>
                                            </div>
                                        </v-radio-group>
                                        <v-divider></v-divider>
                                        <div v-if="hasMore">
                                            <label>
                                                <v-list-item @click="loadMoreAddresses()">
                                                    <v-list-item-action>
                                                        <v-icon>mdi-chevron-down</v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title style="margin-bottom: 0">
                                                            <span style="font-weight: normal">{{ $t('โหลดรายการที่อยู่เพิ่มเติม') }}</span>
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </label>
                                        </div>
                                    </v-list-item-group>
                                </v-card>
                            </div>
                        </v-scroll-y-transition>
                        <!-- แสดง form มาให้กรอก เพื่อสร้างใหม่ หรือ edit -->
                        <v-scroll-y-transition hide-on-leave>
                            <div v-if="isAddressEditorFormShow">
                                <v-alert text
                                         type="info"
                                         class="mb-6"
                                         color="#666">
                                    <div class="just_flex">
                                        <span>{{ editTaxAddressId > 0 ? $t('คุณกำลังแก้ไขที่อยู่เดิม') : $t('คุณกำลังเพิ่มที่อยู่ใหม่') }}</span>
                                        <template v-if="sortedTaxAddresses.length > 0">
                                            <a class="primary--text" @click="closeAddressEditorForm()">{{ $t('เปลี่ยน') }}</a>
                                        </template>
                                    </div>
                                </v-alert>
                            </div>
                        </v-scroll-y-transition>
                        <template v-if="isAddressEditorFormShow">
                            <v-form ref="tax_form"
                                    v-model="formValid">
                                <template v-if="editTaxAddressId === -1">
                                    <v-radio-group mandatory
                                                   row
                                                   v-model="taxAddress.corporate_flag">
                                        <v-radio :label="$t('บุคคลธรรมดา')"
                                                 :value="0"></v-radio>
                                        <v-radio @click="clearTaxForm()"
                                                 :label="$t('นิติบุคคล')"
                                                 :value="1"></v-radio>
                                    </v-radio-group>
                                </template>
                                <v-text-field :label="(taxAddress.corporate_flag)?$t('ชื่อนิติบุคคล'):$t('ชื่อ-นามสกุล')"
                                              persistent-hint
                                              validate-on-blur
                                              auto-grow
                                              outlined
                                              class="mt-1 mb-3"
                                              :rules="commonRules"
                                              v-model.trim="taxAddress.name"></v-text-field>
                                <v-text-field :label="(taxAddress.corporate_flag)?$t('เลขประจำตัวผู้เสียภาษี'):$t('เลขประจำตัวประชาชน')"
                                              auto-grow
                                              outlined
                                              validate-on-blur
                                              class="mb-3"
                                              pattern="\d*"
                                              :rules="taxRules"
                                              counter="13"
                                              maxlength="13"
                                              v-model.trim="taxAddress.tax_id"></v-text-field>
                                <template v-if="taxAddress.corporate_flag">
                                    <v-radio-group mandatory
                                                   :row="isDesktop"
                                                   style="gap: 0.5rem;"
                                                   class="just_flex pt-2"
                                                   v-model="hqRadio">
                                        <div class="needDiv"
                                             style="margin-bottom: 1rem;">
                                            <v-radio :label="$t('สำนักงานใหญ่ (สาขาที่ 00000)')"
                                                     :value=true></v-radio>
                                        </div>
                                        <div class="needDiv just_flex_start">
                                            <v-radio :label="$t('สาขาที่')"
                                                     :value=false></v-radio>
                                            <v-text-field dense
                                                          v-model.trim="taxAddress.branch_id"
                                                          ref="branchIDInput"
                                                          :disabled="hqRadio"
                                                          style="max-width: 100px; margin: -0.6rem 0 0 0.5rem;"
                                                          counter="5"
                                                          pattern="\d*"
                                                          maxlength="5"
                                                          :rules="(!hqRadio)?commonRules:[]"
                                                          class="d-inline-block"
                                                          outlined></v-text-field>
                                        </div>
                                    </v-radio-group>
                                </template>
                                <v-text-field :label="(taxAddress.corporate_flag)?$t('เลขที่ตั้งสำนักงาน'):$t('เลขที่บ้าน')"
                                              :hint="$t('ระบุเฉพาะเลขที่เท่านั้น')"
                                              auto-grow
                                              validate-on-blur
                                              outlined
                                              class="mb-3"
                                              :rules="commonRules"
                                              v-model.trim="taxAddress.houseno"></v-text-field>
                                <v-textarea :label="(taxAddress.corporate_flag)?$t('อาคาร ชั้น ซอย ถนน'):$t('หมู่ที่ ซอย ถนน')"
                                            persistent-hint
                                            auto-grow
                                            validate-on-blur
                                            outlined
                                            class="mb-3"
                                            :rules="commonRules"
                                            v-model.trim="taxAddress.address"></v-textarea>
                                <thailand-address ref="thailandAddress"
                                                  v-model="thailandAddressData"></thailand-address>
                                <v-text-field :label="$t('อีเมล')"
                                              auto-grow
                                              outlined
                                              validate-on-blur
                                              class="mb-3"
                                              :hint="$t('สำหรับกรณีที่ร้านสามารถออก e-tax invoice ได้ ร้านจะส่งใบเสร็จ/ใบกำกับทาง email นี้')"
                                              v-model.trim="taxAddress.email"></v-text-field>
                            </v-form>
                        </template>
                    </v-card-text>

                    <v-divider></v-divider>
                    <v-card-actions style="justify-content: space-between">
                        <v-btn color="darken-1"
                               text
                               @click="closeTaxDialog">
                            <div class="icon-chevron-left"></div>
                            {{ $t('ย้อนกลับ') }}
                        </v-btn>
                        <v-btn color="primary"
                               large
                               style="min-width: 200px"
                               :disabled="disabledSubmitButton"
                               :class="(form_receipt_contact_id !== null)?'ready':''"
                               @click="submitTaxAddress">
                            {{ $t('ยืนยัน') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </component>
        </template>
        <template v-else>
            <div v-if="displayCurrentReceiptContact">
                <v-divider></v-divider>
                <v-list-item>
                    <v-list-item-content>
                        <div class="minor my-4">
                            {{ (hasVat)?$t('ข้อมูลสำหรับออกใบกำกับภาษี'):$t('ข้อมูลสำหรับออกใบเสร็จรับเงิน') }}
                        </div>
                        <v-list-item-title style="white-space: normal">
                            <div class="d-inline-block mr-2"
                                 style="font-weight: bold">{{ receipt.name }}
                            </div>
                            <div class="d-inline-block"
                                 v-if="receipt.corporate_flag">(สาขา {{ receipt.branch_id }})
                            </div>
                        </v-list-item-title>
                        <v-list-item-title style="font-weight: normal">{{ taxFormat(receipt.tax_id) }}</v-list-item-title>
                        <v-list-item-subtitle>{{ receiptAddressFormat(receipt) }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-if="receipt.email">{{ receipt.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </div>
        </template>
    </div>
</template>
<script>
import {VDialog, VBottomSheet} from 'vuetify/lib';
import {mapActions, mapGetters, mapState} from "vuex";
import DebugIndicator from "@/components/DebugTools/DebugIndicator";
import ThailandAddress from "@/components/Address/ThailandAddress.vue";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import store from "@/store";

export default {
    name: "ReceiptTaxInvoice",
    mixins: [
        MixinsHelper,
    ],
    props: {
        isExpanded: {
            required: true,
            type: Boolean,
        },
    },
    components: {
        DebugIndicator,
        VDialog,
        VBottomSheet,
        ThailandAddress,
    },
    data() {
        return {
            formValid: null,
            //enableTaxFlagYet: false, // เคยติ๊ก enable checkbox มาก่อนหรือยัง
            taxDialog: false, //หน้าต่างเลือกที่อยู่ใบกำกับ
            commonRules: [
                v => !!v || this.$t('จำเป็นต้องระบุ'),
            ],
            taxRules: [
                v => !!v || this.$t('จำเป็นต้องระบุ'),
                v => {
                    let valid = true;

                    if (v.length !== 13)
                        valid = false;
                    else {
                        let sum = 0;
                        for (let i = 0; i < 12; i++) {
                            sum += parseInt(v.charAt(i)) * (13 - i);
                        }
                        if ((11 - sum % 11) % 10 !== parseInt(v.charAt(12)))
                            valid = false;
                    }

                    if (!valid)
                        return this.$t('เลขประจำตัวไม่ถูกต้อง');

                    return true;
                },
            ],
            hqRadio: true,
            thailandAddressData: {},
            // สำหรับเพิ่ม / แก้ไข ที่อยู่ใบกำกับ
            taxAddress: {
                corporate_flag: 0,
                name: '',
                tax_id: '',
                branch_id: '',
                houseno: '',
                address: '',
                email: '',
            },
            search_textbox: '',
            overlay: false
        };
    },
    computed: {
        ...mapState({
            orderData: state => state.order.orderData,
            receipt: state => state.order.orderData?.receipt,
            allMoreThanPageLimit: state => state.user.paginated_metadata.taxAddresses.allMoreThanPageLimit,
            hasMore: state => state.user.paginated_metadata.taxAddresses.more,
        }),
        ...mapGetters({
            shop: 'order/shop',
            sortedTaxAddresses: 'user/use_this_sortedTaxAddresses',
            taxAddresses: 'user/use_this_taxAddresses',
            canRequestTax: 'order/canRequestTaxInvoice', // ร้านเปิดระบบใบกำกับหรือไม่
            canEditReceiptContact: 'order/canEditReceiptContact', // ยังสามารถแก้ไขข้อมูลใบเสร็จได้มั้ย
            hasVat: 'order/hasVat', // ร้านเปิดใช้ vat หรือเปล่า
            preferReceiptButNotSelectContact: 'order/preferReceiptButNotSelectContact',
            hasSpecifiedReceiptData: 'order/hasSpecifiedReceiptData',
        }),
        form_receipt_contact_id: {
            set(value) {
                this.$store.commit('order/setForm', {key: 'selectedReceiptContactId', value: value});
            },
            get() {
                return this.$store.state.order.form.selectedReceiptContactId;
            },
        },
        receiptCheckboxChecked: {
            set(value) {
                this.$store.commit('order/setForm', {key: 'receiptCheckboxChecked', value: value});
            },
            get() {
                return this.$store.state.order.form.receiptCheckboxChecked;
            },
        },
        isAddressEditorFormShow() {
            return this.editTaxAddressId !== 0;
        },
        // เปิดฟอร์มเพื่อแก้ไข receiver contact ที่มีอยู่แล้ว 0=ไม่ได้เปิดใช้ฟอร์ม  -1=กำลังสร้างรายการใหม่  id=กำลังแก้ไขรายการไหน
        editTaxAddressId: {
            set(value) {
                this.$store.commit('order/setForm', {editTaxAddressId: value});
            },
            get() {
                return this.$store.state.order.form.editTaxAddressId;
            },
        },
        disabledSubmitButton() {

            // กำลังจะใช้ข้อมูลจากฟอร์ม ก็เลยต้อง validate ข้อมูลในฟอร์ม
            if (this.isAddressEditorFormShow) {
                return !this.formValid;
            }

            return !this.form_receipt_contact_id;
        },
        displayCurrentReceiptContact() {
            return this.receiptCheckboxChecked && this.hasSpecifiedReceiptData;
        },
    },
    methods: {
        ...mapActions({
            updateOrderReceipt: 'order/updateOrderReceipt',
            _loadMoreAddresses: 'user/loadMoreAddresses',
            _searchAddresses: 'user/searchAddresses',
        }),
        closeTaxDialog() {
            if(this.preferReceiptButNotSelectContact) {
                this.receiptCheckboxChecked = false;
            }
            this.taxDialog = false;
        },
        receiptAddressFormat(receipt) {
            if (!receipt) {
                return null;
            }
            const tumbon = receipt.province === 'กรุงเทพมหานคร' ? this.$t('แขวง') : this.$t('ตำบล');
            const ampher = receipt.province === 'กรุงเทพมหานคร' ? this.$t('เขต') : this.$t('อำเภอ');
            const jungwad = receipt.province === 'กรุงเทพมหานคร' ? '' : this.$t('จังหวัด');
            return `${receipt.houseno} ${receipt.address} ${tumbon}${receipt.subdistrict} ${ampher}${receipt.district} ${jungwad}${receipt.province} ${this.$t('รหัสไปรษณีย์')} ${receipt.zipcode}`;
        },
        taxFormat(num) {
            if (num === null || typeof num === 'undefined')
                return '';
            return num.slice(0, 1) + '-' + num.slice(1, 5) + '-' + num.slice(5, 10) + '-' + num.slice(10, 12) + '-' + num.slice(12, 13);
        },
        submitTaxAddress() {
            const payload = {};

            // เลือกเฉยๆ ไม่ได้เปิด editor เพื่อแก้ไข
            if (this.editTaxAddressId === 0)
                payload.receipt_id = this.form_receipt_contact_id;
            else {
                // กำลังแก้ไข receipt contact อันไหนอยู่
                if (this.editTaxAddressId > 0)
                    payload.receipt_id = this.form_receipt_contact_id;

                payload.receipt = Object.assign({}, this.$refs.thailandAddress.getPayload());

                payload.receipt.corporate_flag = this.taxAddress.corporate_flag;
                payload.receipt.address = this.taxAddress.address;
                payload.receipt.name = this.taxAddress.name;
                payload.receipt.tax_id = this.taxAddress.tax_id;
                if (this.taxAddress.corporate_flag) {
                    payload.receipt.branch_id = this.taxAddress.branch_id;
                    if (!payload.receipt.branch_id) {
                        payload.receipt.branch_id = '00000';
                    }
                } else {
                    payload.receipt.branch_id = '';
                }
                payload.receipt.houseno = this.taxAddress.houseno;
                payload.receipt.email = this.taxAddress.email;
            }
            store.dispatch('order/setIsShowOverlay', true);
            this.updateOrderReceipt(payload)
                .then(() => {
                    store.dispatch('order/setIsShowOverlay', false);
                    this.taxDialog = false;
                });
        },
        addNewAddress() { // กดปุ่มเพิ่มที่อยู่ใหม่
            this.editTaxAddress(-1);
        },
        // เปิดฟอร์มเพื่อแก้ไข receiver contact ที่มีอยู่แล้ว
        editTaxAddress(id) {
            this.editTaxAddressId = id;
            if (id === -1) { // เพิ่มที่อยู่ใหม่ จะส่ง id มาเป็น -1
                this.form_receipt_contact_id = -1;
                this.taxAddress.corporate_flag = 0;

                this.clearTaxForm();
                this.taxAddress.name = this.orderData.receiver.name;
                if(this.orderData.contact_email){
                    this.taxAddress.email = this.orderData.contact_email;
                }
                else if(this.orderData.receiver && this.orderData.receiver.email){
                    this.taxAddress.email = this.orderData.receiver.email;
                }

                // เพราะว่า บ้านเลขที่ต้องแยกไปเป็น houseno
                // this.taxAddress.address = this.orderData.receiver.address + ' ' + this.orderData.receiver.subdistrict + ' ' + this.orderData.receiver.district + ' ' + this.orderData.receiver.province + ' ' + this.orderData.receiver.zipcode;
            }
            else if (id === false) {
                this.extractReceiptData(this.orderData.receipt);
                this.taxDialog = true;
            }
            else { // แก้ไขที่อยู่เดิม
                this.form_receipt_contact_id = id; // เวลากด "แก้ไข" ที่อยู่ เราจะให้เลือกตัวเลือกนี้ไว้ด้วยเลย
                this.extractReceiptData(this.taxAddresses[id]);
            }
            let that = this;
            setTimeout(function () {
                that.$refs.taxScroll.scrollTop = 0;
            }, 300);
        },
        clearTaxForm() {
            this.taxAddress.name = '';
            this.taxAddress.email = '';
            this.taxAddress.address = '';
            this.taxAddress.tax_id = '';
            this.taxAddress.branch_id = '00000';
            this.taxAddress.houseno = '';
            this.thailandAddressData = {};
        },
        closeAddressEditorForm() {
            this.editTaxAddressId = 0;
        },
        // กดเปิดเพื่อเปลี่ยน ข้อมูล receipt : แสดงเป็น dialog ขึ้นมา
        openTaxDialog() {
            if(this.checkNoPermissionAndPopup()) return;
            if (this.orderData.receipt_id) {
                // เพื่อให้ย้อนกลับมาใน step ที่แสดง list ของ contacts เพื่อให้เลือก
                this.editTaxAddressId = 0;
                this.form_receipt_contact_id = this.orderData.receipt_id;
            } else {
                if (this.sortedTaxAddresses && this.sortedTaxAddresses.length === 0) {
                    this.addNewAddress();
                }
            }
            this.taxDialog = true;
        },
        extractReceiptData(receipt) {
            this.taxAddress.corporate_flag = receipt.corporate_flag;
            this.taxAddress.name = receipt.name;
            this.taxAddress.houseno = receipt.houseno;
            this.taxAddress.address = receipt.address;
            this.taxAddress.tax_id = receipt.tax_id;
            this.taxAddress.corporate_flag = receipt.corporate_flag;
            this.taxAddress.branch_id = (receipt.corporate_flag) ? receipt.branch_id : '';
            this.taxAddress.email = receipt.email? receipt.email : '';

            const {subdistrict_id, district_id, province_id} = receipt;
            let selectedPostalList;
            let custom_province = '';
            let custom_district = '';
            let custom_subdistrict = '';
            if (subdistrict_id && district_id && province_id) {
                selectedPostalList = `${subdistrict_id} ${district_id} ${province_id}`;
            } else {
                selectedPostalList = 'custom';
                custom_subdistrict = receipt.subdistrict;
                custom_district = receipt.district;
                custom_province = receipt.province;
            }

            this.thailandAddressData = {
                postalCode: receipt.zipcode,
                selectedPostalList,
                custom_subdistrict,
                custom_district,
                custom_province,
            };
        },
        searchAddressesEnter(){
            if(this.search_textbox && this.search_textbox != ""){
                this.searchAddresses();
            }else{
                this.abortSearchAddresses();
            }
            event.target.blur();
            event.preventDefault();
            return false;
        },
        async loadMoreAddresses() {
            await this._loadMoreAddresses({type: 'taxAddresses'});
        },
        async searchAddresses() {
            await this._searchAddresses({type: 'taxAddresses', keyword: this.search_textbox});
        },
        async abortSearchAddresses() {
            await this._searchAddresses({type: 'taxAddresses', keyword: false});
            this.search_textbox = "";
        },
    },
    watch: {
        receiptCheckboxChecked(newValue) {
            // ถ้ากดขอใบกำกับครั้งแรกให้ dialog ขึ้นมา
            if (newValue) {
                if (this.preferReceiptButNotSelectContact) {
                    this.openTaxDialog();
                }
            }
        },
        hqRadio(newValue) {
            if (!newValue)
                this.$nextTick(() => this.$refs['branchIDInput'].focus())
            else
                this.taxAddress.branch_id = '00000';
        },
        'taxAddress.branch_id': function (newValue) {
            if (newValue === '00000') {
                this.hqRadio = true;
                this.taxAddress.branch_id = '';
            }
        },
    },
    mounted() {
        if (this.hasSpecifiedReceiptData) {
            // this.enableTaxFlagYet = true;
            this.receiptCheckboxChecked = true;
            this.form_receipt_contact_id = this.orderData.receipt_id;
        }
    },
}
</script>
<style scoped></style>