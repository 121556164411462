<script>
export default {
    name: "MixinsFormExpandOrderDetail",
    computed: {
        expandOrderDetail: {
            get: function() {
                return this.$store.state.order.form.isExpandOrderDetail;
            },
            set: function (newValue) {
                this.$store.commit('order/setForm', {isExpandOrderDetail: newValue});
            },
        },
        orderFixedTop: {
            get: function() {
                return this.$store.state.order.form.isOrderFixedTop;
            },
            set: function (newValue) {
                this.$store.commit('order/setForm', {isOrderFixedTop: newValue});
            },
        },
    },
}
</script>

<style scoped>

</style>