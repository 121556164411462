<template>
    <div>
        <div @click="handleCopyStatus">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "HandleCopyComponent",
    methods: {
        handleCopyStatus() {
            this.$store.dispatch('order/setIsCopied', true);
        },
    },
    mounted() {
    }
}
</script>