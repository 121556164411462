<template>
    <div>
        <template v-if="isSelected">
            <v-tooltip
                top
                max-width="200"
            >
                <template v-slot:activator="{ on }">
                    <v-icon class="position-absolute" style="right: 1.25rem; top: 1.25rem" v-on="on">
                        mdi-information-variant
                    </v-icon>
                </template>
                {{hint}}
            </v-tooltip>
        </template>
        <v-slide-y-transition hide-on-leave>
            <div v-if="isSelected">
                <slot></slot>
                <div class="buyerProtection" v-if="paymentMethod.is_lnwpay || paymentMethod.is_lnwpay_lite">
                    <span>{{ $t('คุณจะได้รับความคุ้มครองผู้ซื้อโดย') }}</span>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs"
                                                  v-on="on">
                                               <img style="height: 15px; vertical-align: text-top" alt="" src="@/assets/lnwpay_logo_gradient.png" />
                                            </span>
                        </template>
                        LnwPay
                    </v-tooltip>
                    <div v-if="paymentMethod.is_lnwpay_lite" class="minor">
                        * {{$t('ยกเว้นวิธีที่ชำระเงินกับร้านโดยตรง')}}
                    </div>
                </div>
            </div>
        </v-slide-y-transition>
    </div>
</template>

<script>
    export default {
        name: "PaymentProvidersWrapper",
        props: {
            isSelected: {
                required: true,
                type: Boolean,
            },
            hint: {
                required: true,
                type: String,
            },
            paymentMethod: {
                required: true,
                type: Object,
            },
        },
    }
</script>

<style scoped>

</style>