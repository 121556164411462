<template>
    <v-row class="fill-height px-4 py-3"
           align-content="center"
           justify="center">
        <v-col class="text-subtitle-1 text-center"
               cols="12">
        </v-col>
        <template v-if="isWaitingForChangeStatus">
            <v-col class="text-h2 text-center"
                   cols="12">
                Thank you.
            </v-col>
            <v-col class="text-caption text-center success_text"
                   cols="12">
                <v-tooltip class="d-inline-block"
                           top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            v-bind="attrs"
                            v-on="on"
                            class="ma-2 py-1"
                            color="success">
                            <v-icon left small>
                                mdi-check-bold
                            </v-icon>
                            {{ $t('ชำระเงินเรียบร้อย') }}
                        </v-chip>
                    </template>
                    <template v-if="callbackStates.pay_t">{{ this.$d(callbackStates.pay_t, 'long') }}</template>
                </v-tooltip>
                <v-divider class="mt-6"></v-divider>
            </v-col>
            <v-col class="text-subtitle-1 text-center"
                   cols="12">
                {{ $t('กำลังดำเนินการปรับสถานะออเดอร์') }}...
            </v-col>
            <v-col cols="12">
                <v-progress-linear color="primary accent-4"
                                   indeterminate
                                   rounded
                                   class="mx-auto"
                                   style="max-width: 300px"
                                   height="6"></v-progress-linear>
            </v-col>
        </template>
        <template v-else>
            <v-col class="text-h4 text-center"
                   cols="12">
                {{ $t('ชำระเงินไม่สำเร็จ') }}
            </v-col>
            <v-col class="text-caption text-center"
                   cols="12">
                <div>
                    <v-tooltip class="d-inline-block"
                               top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                v-bind="attrs"
                                v-on="on"
                                class="ma-2 py-1"
                                color="error">
                                <v-icon left small>
                                    mdi-close-thick
                                </v-icon>
                                <template v-if="isCancel">
                                    {{ $t('การชำระเงินถูกยกเลิก') }}
                                </template>
                                <template v-else-if="isFail">
                                    {{ $t('การชำระเงินมีข้อผิดพลาด') }}
                                </template>
                            </v-chip>
                        </template>
                        <template v-if="callbackStates.pay_t">{{ this.$d(callbackStates.pay_t, 'long') }}</template>
                    </v-tooltip>
                    <div class="text-caption disabled">
                        {{ callbackStates.pay_em }} · [#{{ callbackStates.pay_ec }}]
                        <v-divider class="mt-6"></v-divider>
                    </div>
                </div>
            </v-col>
            <v-col class="text-subtitle-2 text-center"
                   cols="12">
                {{ $t('กรุณาชำระใหม่อีกครั้ง หรือชำระด้วยช่องทางอื่นๆ ') }}
            </v-col>
        </template>
    </v-row>
</template>
<script>
import {mapState} from "vuex";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";


export default {
    name: "LnwPayCallBack",
    data() {
        return {
        };
    },
    computed: {
        ...mapState({
            callbackStates: state => state.order.lnwpayCallbackStates,
        }),
        isCancel() {
            return this.callbackStates?.pay_s === 'cancel';
        },
        isFail() {
            return this.callbackStates?.pay_s === 'fail';
        },
        isWaitingForChangeStatus() {
            return this.callbackStates?.pay_s === 'success' || this.callbackStates?.pay_s === 'pending';
        }
    },
    mixins: [MixinsHelper],
    methods: {
    }
}
</script>