<template>
    <div v-show="!expandOrderDetail || orderFixedTop"
         id="infoFixedTop"
         class="orderInfo"
         :class="orderFixedTop?'fixed-top':'sticky-top'">
        <template>
            <div class="info_content">
                <div @click="toggleInfo"
                     class="top">
                    <div class="items">
                        <v-alert type="error" dense v-if="cpOrderItems.length <= 0" style="margin: auto">
                            {{ $t('ไม่มีรายการสินค้า') }}
                        </v-alert>
                        <!--                                <div class="mr-2">ใบสั่งซื้อ : </div>-->
                        <div :key="item.iid"
                             class="item"
                             v-for="item in cpOrderItems">
                            <div class="image">
                                <v-badge color="grey darken-2"
                                         overlap
                                         :content="number_format(item.quantity)"
                                         style="font-weight: bold;font-size: 10px;"><img :src="item.thumbnail"
                                                                         :alt="item.name"/>
                                </v-badge>
                            </div>
                            <div v-if="cpOrderItems.length === 1"
                                 class="item-name">
                                {{ item.name }}
                            </div>
                        </div>
                    </div>
                    <div class="data">
                        <div class="data">
                            <div class="text-no-wrap">
                                <span class="net_price"
                                      :class="(updateNetPrice)?'slideDown':''">
                                    <span class="baht">฿</span>{{ cpNetPrice }}
                                </span>
                                <v-icon class="primary white--text" :class="((!orderFixedTop && expandOrderDetail))?'flip':''">mdi-chevron-down</v-icon>
                            </div>
                            <div class="minor">
                                <template v-if="!(isOrderNoShipping) && !hasShippingPrice">
                                    {{ $t('ยังไม่รวมค่าส่ง') }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import MixinsFormExpandOrderDetail from "@/components/Order/mixins/MixinsFormExpandOrderDetail.vue";
import MixinsTheOrderDetailMutual from "@/components/Order/mixins/MixinsTheOrderDetailMutual.vue";
import Helper from "@/libraries/Helper";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "TheOrderDetailFixedTop",
    mixins: [
        MixinsFormExpandOrderDetail,
        MixinsTheOrderDetailMutual,
        MixinsHelper,
    ],
    data() {
        return {
            updateNetPrice: false, // for slideDown animation
        };
    },
    computed: {
        ...mapGetters({
            isOwnerCreateFlag: 'order/isOwnerCreateFlag',
            isOrderNoShipping : 'order/isOrderNoShipping',
            isShowOrderDetailOnTop : 'order/isShowOrderDetailOnTop'
        }),
        ...mapState({
            status: state => state.order.orderData?.order_status,
        }),
    },
    methods: {
        number_format(num, decimal) {
            return Helper.number_format(num, decimal);
        },
        toggleInfo() {
            if (this.isDesktop) {
                return false;
            }
            if (this.orderFixedTop) {
                this.expandOrderDetail = true;
            } else {
                this.expandOrderDetail = !this.expandOrderDetail;
            }
            if(!this.isShowOrderDetailOnTop){
                this.$emit('goToOrderInfo');
            }else{
                this.$emit('goToHeader');
            }
        },
    },
    watch: {
        cpNetPrice: function (newValue, oldValue) {
            // noinspection EqualityComparisonWithCoercionJS
            if (newValue != oldValue) {
                this.updateNetPrice = true;
                let that = this;
                setTimeout(function () {
                    that.updateNetPrice = false;
                }, 1000);
            }
        },
    },
}
</script>

<style scoped>

</style>