<template>
    <div>
        <!-- input: zipcode -->
        <v-text-field style="max-width: 160px"
                      :label="$t('รหัสไปรษณีย์')"
                      :rules="rulesPostal"
                      validate-on-blur
                      pattern="\d*"
                      maxlength=5
                      clearable
                      v-disabled-icon-focus
                      outlined
                      v-model="postalCode"
                      @input="onInputPostalCode"></v-text-field>

        <!-- Alert -->
        <v-alert v-show="isPostalCodeNotFound"
                 type="warning"
                 dismissible
                 dense
                 transition="slide-y-transition">
            {{ $t('เราไม่พบที่อยู่ตามรหัสไปรษณีย์ของคุณ หากคุณมั่นใจว่ารหัสถูกต้อง กรุณากรอกที่อยู่ของคุณด้านล่างนี้') }}
        </v-alert>
        <v-alert v-show="false && !isPostalCodeNotFound && postalCode && selectedPostalList === 'custom'"
                 type="info"
                 color="grey"
                 dismissible
                 transition="slide-y-transition">
            {{ $t('คุณกำลังเพิ่มข้อมูลที่อยู่รหัสไปรษณีย์') }} {{postalCode}}
        </v-alert>

        <!-- ตัวเลือก ตำบล อำเภอ จังหวัด : หลังจากระบุ postalCode -->
        <v-autocomplete v-if="isShowAutocompletePostalList"
                        v-model="selectedPostalList"
                        class="mb-4"
                        :hint="(!postalCode)?$t('กรุณาระบุรหัสไปรษณีย์ก่อน'):''"
                        persistent-hint
                        autocomplete="off"
                        :items="comboPostalList"
                        item-text="text"
                        item-value="id"
                        :label="$t('ตำบล อำเภอ จังหวัด')"
                        outlined
                        :rules="rulesSelectedPostalList"
                        :disabled="!isShowPostalListItems"></v-autocomplete>

        <!-- dropdown ที่ให้ผู้ใช้พิมพ์เอง ในกรณีที่ไม่เจอตัวเลือกในฐานข้อมูลของเรา -->
        <div :class="(isDesktop)?'d-flex flex-nowrap':''" :style="(isDesktop)?'gap: 10px;':''" v-else-if="selectedPostalList === 'custom' || isPostalCodeNotFound">
            <v-combobox :label="$t('จังหวัด')"
                        outlined
                        :rules="rulesCommon"
                        :items=provinceList
                        v-model="custom_province"
                        :style="(isDesktop)?'flex: 1 0 32%':''"></v-combobox>
            <v-combobox :label="$t('อำเภอ')"
                        outlined
                        :rules="rulesCommon"
                        :items=districtList
                        v-model="custom_district"
                        :style="(isDesktop)?'flex: 1 0 32%':''"></v-combobox>
            <v-combobox :label="$t('ตำบล')"
                        outlined
                        :rules="rulesCommon"
                        :items=subdistrictList
                        v-model="custom_subdistrict"
                        :style="(isDesktop)?'flex: 1 0 32%':''"></v-combobox>
        </div>

    </div>
</template>

<script>
import zipdb from "../../../resources/data-lnw-with-code.json";
import zipdb_en from "../../../resources/data-lnw-with-code-en.json";
import MixinsDisabledIconFocus from "@/mixins/MixinsDisabledIconFocus.vue";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "ThailandAddress",
    mixins: [MixinsDisabledIconFocus, MixinsHelper,
    ],
    props: {
        locale : {
          type : String,
          default : 'th'
        },
        value: {
            type: Object,
        },
    },
    data() {
        return {
            postalCode: null,
            selectedPostalList: null,
            custom_province: null,
            custom_district: null,
            custom_subdistrict: null,
        };
    },
    computed: {
        rulesPostal() {
            return [
                v => !!v || this.$t('จำเป็นต้องระบุ'),
                v => (v && (v.length === 5)) || this.$t('รหัสไปรษณีย์ไม่ถูกต้อง')
            ];
        },
        rulesCommon() {
            return [
                v => !!v || this.$t('จำเป็นต้องระบุ'),
            ];
        },
        rulesSelectedPostalList() {
            if(this.isShowAutocompletePostalList){
                return [
                    v => !!v || 'จำเป็นต้องระบุ',
                ];
            }else{
                return true;
            }
        },

        // จะแสดงตัวเลือก ตำบล / อำเภอ / จังหวัด มั้ย
        isShowPostalListItems() {
            return this.postalList[this.postalCode] && this.selectedPostalList !== 'custom';
        },

        // จะแสดง autocomplete postalList มั้ย
        isShowAutocompletePostalList() {
            return !this.postalCode || (this.selectedPostalList !== 'custom' && !this.isPostalCodeNotFound);
        },

        // กรอก zipcode แล้ว แต่ zipcode ไม่มีในฐานข้อมูลของเรา
        isPostalCodeNotFound() {
            return this.postalCode && this.postalCode.length === 5 && !this.postalList[this.postalCode];
        },
        zipdb_lang() {
            let db;
            if( this.locale == 'en') {
                db = zipdb_en;
            }else{
                db = zipdb;
            }
            return db;
        },
        postalList() {
            const output = {};

            this.zipdb_lang.forEach((provinceEntry) => {
                let pname = provinceEntry[0];
                let districtList = provinceEntry[1];
                let provinceId = provinceEntry[2];
                // let pid = provinceEntry[2];
                // if (!provinceEntry[3] || provinceEntry[3] === 0)
                //     return;
                // let province_code = zeroFill(provinceEntry[3]);
                districtList.forEach((districtEntry) => {
                    let dname = districtEntry[0];
                    let subdistrictList = districtEntry[1];
                    let districtId = districtEntry[2];
                    // if (!districtEntry[3] || districtEntry[3] === 0)
                    //     return;
                    // let district_code = province_code + zeroFill(districtEntry[3]);
                    subdistrictList.forEach((subdistrictEntry) => {
                        let sname = subdistrictEntry[0];
                        let zipCodeList = subdistrictEntry[1];
                        let subdistrictId = subdistrictEntry[2];
                        // if (!subdistrictEntry[3] || subdistrictEntry[3] === 0)
                        //     return;
                        // let subdistrict_code = district_code + zeroFill(subdistrictEntry[3]);
                        zipCodeList.forEach((zipCode) => {
                            if (!output.hasOwnProperty(zipCode))
                                output[zipCode] = [];

                            output[zipCode].push([
                                sname,
                                dname,
                                pname,
                                subdistrictId,
                                districtId,
                                provinceId
                            ]);
                        })
                    })
                })
            });
            return output;
        },
        comboPostalList() {
            if (!this.postalList[this.postalCode])
                return [];
            const output = [];
            // item = [ ตำบล, อำเภอ, จังหวัด, ตำบลid, อำเภอid, จังหวัดid ]
            this.postalList[this.postalCode].forEach((subdistrict) => {
                output.push({text: subdistrict.slice(0, 3).join(' '), id: subdistrict.slice(3, 6).join(' ')})
            })
            output.push({text: this.$t('กำหนดใหม่ (กรณีไม่มีในตัวเลือกที่อยู่ของคุณ)'), id: 'custom'})
            return output;
        },
        provinceList() {
            const output = [];
            this.zipdb_lang.forEach((provinceEntry) => {
                output.push(provinceEntry[0]);
            });
            return output;
        },
        districtList() {
            if (!this.custom_province)
                return [];

            const list = [];

            for (let provinceEntry of this.zipdb_lang) {
                if (provinceEntry[0] === this.custom_province) {
                    for (let districtEntry of provinceEntry[1]) {
                        list.push(districtEntry[0]);
                    }
                    break;
                }
            }

            return list;
        },
        subdistrictList() {
            if (!this.custom_province || !this.custom_province)
                return [];

            const list = [];

            let stop = false;

            for (let provinceEntry of this.zipdb_lang) {
                if (provinceEntry[0] === this.custom_province) {
                    for (let districtEntry of provinceEntry[1]) {
                        if (districtEntry[0] === this.custom_district) {
                            for (let subdistrictEntry of districtEntry[1]) {
                                list.push(subdistrictEntry[0]);
                            }
                            stop = true;
                        }
                        if (stop) {
                            break;
                        }
                    }
                }
            }

            return list;
        },
    },
    methods: {
        onInputPostalCode() {
            if (this.isPostalCodeNotFound) {
                this.selectedPostalList = 'custom';
            }
            else {
                this.selectedPostalList = null;
            }
        },
        emitVar() {
            this.postalCode = this.value?.postalCode ? this.value?.postalCode : null;
            this.selectedPostalList = this.value?.selectedPostalList ? this.value?.selectedPostalList : null;
            this.custom_province = this.value?.custom_province ? this.value?.custom_province : null;
            this.custom_district = this.value?.custom_district ? this.value?.custom_district : null;
            this.custom_subdistrict = this.value?.custom_subdistrict ? this.value?.custom_subdistrict : null;
        },
        emitInput() {
            this.$emit('input', {
                postalCode: this.postalCode,
                selectedPostalList: this.selectedPostalList,
                custom_province: this.custom_province,
                custom_district: this.custom_district,
                custom_subdistrict: this.custom_subdistrict,
            });
        },
        getPayload() {
            const payload = {};
            payload.zipcode = this.postalCode;

            // ที่ใส่ || !this.postalList[this.postalCode] ไว้ด้วย เพื่อดักกรณีที่ไม่เจอ zipcode ใน postalList ก็ให้ทำงานเสมือนว่าเป็น custom ไปเลย
            if (this.selectedPostalList === 'custom' || !this.postalList[this.postalCode]) {
                payload.province = this.custom_province;
                payload.district = this.custom_district;
                payload.subdistrict = this.custom_subdistrict;
            } else {
                // "subdistrict-id district-id province-id"
                const selectedPostal = this.selectedPostalList.split(" ");
                const selected_subdistrict_id = parseInt(selectedPostal[0]);
                this.postalList[this.postalCode].forEach((item) => {
                    // [ ตำบล, อำเภอ, จังหวัด, ตำบลid, อำเภอid, จังหวัดid ]
                    if (item[3] === selected_subdistrict_id) {
                        payload.subdistrict = item[0];
                        payload.district = item[1];
                        payload.province = item[2];
                        payload.subdistrict_id = item[3];
                        payload.district_id = item[4];
                        payload.province_id = item[5];
                    }
                });
            }

            return payload;
        },
    },
    watch: {
        value: {
            handler: function(){
                this.emitVar();
            },
            deep: true
        },
        selectedPostalList() {this.emitInput();},
        custom_province() {this.emitInput();},
        custom_district() {this.emitInput();},
        custom_subdistrict() {this.emitInput();},
    },
    mounted() {
        this.emitVar();
        if (this.selectedPostalList !== 'custom' && this.selectedPostalList !== null) {
            if (Array.isArray(this.comboPostalList)) {
                let valid = false;
                for (const item of this.comboPostalList) {
                    if (item.id === this.selectedPostalList) {
                        valid = true;
                        break;
                    }
                }
                if (!valid) {
                    if (this.isPostalCodeNotFound)
                        this.selectedPostalList = null;
                    else
                        this.selectedPostalList = 'custom';
                }
                else {
                    this.custom_subdistrict = null;
                    this.custom_district = null;
                    this.custom_province = null;
                }
            }
        }
    },
}
</script>

<style scoped>

</style>