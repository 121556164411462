<script>
export default {
    name: "MixinsGetPaymentProviderImgUrl",
    methods: {
        getPaymentProviderImgUrl(payment_method_key, provider_key, {provider}) {
            // กรณีที่ backend ส่งระบุมาเลย ว่าให้ใช้รูป provider จาก URL ไหน
            if (provider && provider.icon) {
                return provider.icon;
            }
            if (payment_method_key === "internet_banking") {
                let pic = provider_key.replace('modernpay_', '');
                return require('@/assets/payment/online-bank/' + pic + '.png');
            } else if (payment_method_key === "counter_payment") {
                return require('@/assets/payment/counter/' + provider_key + '.png');
            } else if (payment_method_key === "e_wallet") {
                return require('@/assets/payment/onlinepayment/' + provider_key + '.png');
            }

            return false;
        }
    },
}
</script>