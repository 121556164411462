<template>
    <div id="chatContainer" class="chatContainer">
        <div class="disputeArea">
            <div class="disputeDetailArea ">
                <div class="listMessage ownerView pb-0"
                     ref="listMessage">
                    <div v-for="(message, index) in message_list"
                         class="messageZone"
                         :key="message.message_id"
                         :id="(index == message_list.length - 1)?'lastMessage':''"
                         :class="message.className">
                        <template v-if="message.role == 'system'">
                            <div class="systemDetail">
                            <span class="message_text"
                                  v-text="message.text"></span> &nbsp;
                                <div class="message_time"
                                     v-text="clock.show_datetime(message.time)"></div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="userDetail">
                                <div :class="(message.role=='buyer')?'buyer_avatar50':'seller_avatar50'">
                                    <img v-if="message.user_avatar"
                                         class=""
                                         :src="message.user_avatar"/>
                                    <v-icon size="50"
                                            style="margin-top: -5px;"
                                            color="grey"
                                            v-else>mdi-account-circle
                                    </v-icon>
                                </div>
                                <!--                                <div class="name"-->
                                <!--                                     v-text="message.user_name"></div>-->
                            </div>
                            <div class="userMessage">
                                <div v-if="message.images"
                                     class="message_images">
                                    <a v-for="(image,index) in message.images"
                                       :href="image"
                                       :key="index"
                                       target="_blank"
                                       class="message_image_link"><img :src="image"
                                                                       class="message_image"
                                                                       @load="scroll_to_bottom"></a>
                                </div>
                                <div class="message_text"
                                     v-text="message.text"></div>
<!--                                <v-tooltip bottom>-->
<!--                                    <template v-slot:activator="{ on }">-->
<!--                                        <div class="message_time"-->
<!--                                             v-on="on"-->
<!--                                             v-text="clock.diff_now_text(message.time)"></div>-->
<!--                                    </template>-->
<!--                                    {{ clock.show_datetime(message.time) }}-->
<!--                                </v-tooltip>-->
                                <div class="message_time"
                                     @click="show_full_datetime = !show_full_datetime"
                                     v-text="show_full_datetime?clock.show_datetime(message.time):clock.diff_now_text(message.time)"></div>
                            </div>
                        </template>
                    </div>
                    <div id="newMessageContainer"
                         class="messageZone customer"
                         style="padding-right: 0;">
                        <div class="userDetail">
                            <div class="buyer_avatar50">
                                <div v-if="userData"
                                     class="v-avatar">
                                    <img :alt="userData.name"
                                         :src="userData.avatar"/>
                                </div>
                                <span v-else><v-icon size="50">mdi-account-circle</v-icon></span>
                            </div>
                            <!--                            <div class="name">{{userData.name}}</div>-->
                        </div>
                        <div style="width: 100%;">
                            <v-textarea
                                        v-model="draft_message"
                                        hide-details="auto"
                                        flat
                                        rows="3"
                                        label="ฝากข้อความ..."
                                        style="border-radius: 0px 20px 20px 20px"
                                        outlined>
                                <!--                                                    <template v-slot:prepend-inner>-->
                                <!--                                                        <v-icon class="mr-1">mdi-message-image-outline</v-icon>-->
                                <!--                                                    </template>-->
                            </v-textarea>
                            <div class="just_flex_start"
                                 :class="!isDesktop?'flex-wrap mt-0':'mt-4'">
                                <v-file-input label="อัพโหลดภาพ"
                                              truncate-length="15"
                                              color="primary"
                                              class="mr-4"
                                              prepend-icon="mdi-image-plus"
                                              prepend-inner-icon=""></v-file-input>
                                <v-btn @click="send_message"
                                       x-large
                                       :block="!isDesktop"
                                       style="min-width: 200px"
                                       class="mt-0"
                                       :disabled="!draft_message"
                                       color="primary">
                                    ส่งข้อความ
                                </v-btn>
                            </div>
                        </div>
                    </div>
                    <template v-if="isLnwPay">
                        <!--                        กรณีมี order ไม่ได้อยู่ในสถานะร้องเรียน (ยังไม่ได้เปิดร้องเรียน || เรียบร้อยแล้ว) จะมีปุ่มให้เปิดร้องเรียน-->
                        <v-alert v-if="!is_dispute"
                                 text
                                 class="mt-10 px-5 mb-0"
                                 color="primary">
                            <h3 class="headline">คุณได้รับความคุ้มครองโดย LnwPay</h3>
                            <div style="font-size: small">LnwPay ที่เป็นตัวกลางรับชำระเงินแทนร้านค้า ทำให้เราสามารถคุ้มครองและป้องกันการทุจริตได้ทั้งผู้ซื้อและผู้ขาย อ่านรายละเอียดเพิ่มเติมได้ที่
                                <a href="https://www.lnwpay.com/help"
                                   target="_blank">ศูนย์ช่วยเหลือผู้ซื้อ</a></div>
                            <v-divider class="my-4 info"
                                       style="opacity: 0.22"></v-divider>
                            <div class="">
                                <h3 class="headline">
                                    <v-icon class="mr-2"
                                            color="primary">mdi-lifebuoy
                                    </v-icon>
                                    ต้องการความช่วยเหลือ ?
                                </h3>
                            </div>
                            <div class="just_flex"
                                 :class="(isDesktop)?'':'flex-wrap'">
                                <div style="font-size: medium;">แจ้งปัญหาให้ร้านค้าและ LnwPay ได้ทราบพร้อมกันทุกฝ่าย เพื่อช่วยเหลือคุณจนถึงที่สุด
                                </div>
                                <div class="flex-grow-1" :class="isDesktop?'pl-3':'mt-4 mb-2'">
                                    <component :is="isDesktop?'VDialog':'VBottomSheet'" v-model="disputeDialog"
                                               persistent
                                               :max-width="isDesktop?'600px':'100%'"
                                               :transition="isDesktop?'dialog-transition':'dialog-bottom-transition'">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn x-large
                                                   outlined
                                                   block
                                                   color="primary"
                                                   v-bind="attrs"
                                                   v-on="on">
                                                แจ้งปัญหา
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title class="headline just_flex_start">
                                                <div>แจ้งปัญหาและเปิดข้อร้องเรียน</div>
                                                <div>
                                                    <v-icon large
                                                            @click="disputeDialog = false;">mdi-close
                                                    </v-icon>
                                                </div>
                                            </v-card-title>
                                            <v-card-text>
                                                กรณีไม่ได้รับสินค้า สินค้าล่าช้า สุญหาย สินค้าที่ได้รับมีสภาพเสียหายหรือไม่ตรงกับที่ระบุไว้บนหน้าเว็บ กรุณาระบุรายละเอียด
                                            </v-card-text>
                                            <div class="chatContainer">
                                                <div class="disputeArea py-0">
                                                    <div class="disputeDetailArea">
                                                        <div class="listMessage ownerView">
                                                            <div class="messageZone customer"
                                                                 style="padding-right: 0;">
                                                                <div class="userDetail">
                                                                    <div class="buyer_avatar50">
                                                                        <div v-if="userData"
                                                                             class="v-avatar">
                                                                            <img :alt="userData.name"
                                                                                 :src="userData.avatar"/>
                                                                        </div>
                                                                        <span v-else><v-icon size="50">mdi-account-circle</v-icon></span>
                                                                    </div>
                                                                    <!--                            <div class="name">{{userData.name}}</div>-->
                                                                </div>
                                                                <div style="width: 100%;">
                                                                    <v-textarea hide-details="auto"
                                                                                v-model="draft_message"
                                                                                flat
                                                                                rows="3"
                                                                                label="รายละเอียดปัญหาที่เกิดขึ้น..."
                                                                                style="border-radius: 0px 20px 20px 20px"
                                                                                outlined>
                                                                        <!--                                                    <template v-slot:prepend-inner>-->
                                                                        <!--                                                        <v-icon class="mr-1">mdi-message-image-outline</v-icon>-->
                                                                        <!--                                                    </template>-->
                                                                    </v-textarea>
                                                                    <div class="just_flex_start"
                                                                         :class="!isDesktop?'flex-wrap mt-0':'mt-4'">
                                                                        <v-file-input label="อัพโหลดภาพ"
                                                                                      truncate-length="15"
                                                                                      color="primary"
                                                                                      class="mr-4"
                                                                                      prepend-icon="mdi-image-plus"
                                                                                      prepend-inner-icon=""></v-file-input>
                                                                        <v-btn @click="confirm_dispute"
                                                                               x-large
                                                                               :block="!isDesktop"
                                                                               style="min-width: 200px"
                                                                               class="mt-0"
                                                                               :disabled="!draft_message"
                                                                               color="error">
                                                                            แจ้งข้อร้องเรียน
                                                                        </v-btn>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-card>
                                    </component>
                                </div>
                            </div>
                        </v-alert>
                        <!--                        กรณีมี order ้อยู่ในสถานะร้องเรียน จะมีปุ่มให้เป็นเรียบร้อยได้-->
                        <v-alert v-else
                                 text
                                 class="mt-10 px-5 mb-0"
                                 color="success">
                            <h3 class="headline">
                                <v-icon class="mr-2"
                                        color="success">mdi-lifebuoy
                                </v-icon>
                                เรียบร้อยแล้ว ?
                            </h3>
                            <div class="just_flex"
                                 :class="(isDesktop)?'':'flex-wrap'">
                                <div class="pr-3"
                                     style="font-size: medium;">กรุณายืนยันว่าคุณได้รับการแก้ไขปัญหาที่ร้องเรียนเรียบร้อยแล้ว
                                </div>
                                <div>
                                    <v-dialog v-model="disputeDialog"
                                              width="400">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn x-large
                                                   color="success"
                                                   v-bind="attrs"
                                                   v-on="on">
                                                ยืนยันว่าเรียบร้อย
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title class="headline just_flex_start">
                                                <div>ยืนยันได้รับการแก้ไขแล้ว</div>
                                                <div>
                                                    <v-icon large
                                                            @click="disputeDialog = false;">mdi-close
                                                    </v-icon>
                                                </div>
                                            </v-card-title>
                                            <v-card-text class="">
                                                คุณยังสามารถร้องเรียนใหม่ได้หากมีปัญหาในภายหลัง
                                            </v-card-text>
                                            <v-card-actions class="just_flex_start">
                                                <v-btn color="darken-1"
                                                       text
                                                       @click="disputeDialog = false">
                                                    <div class="icon-chevron-left"></div>
                                                    ย้อนกลับ
                                                </v-btn>
                                                <v-btn color="primary"
                                                       large
                                                       style="min-width: 200px"
                                                       @click="confirm_complete">
                                                    ใช่ เรียบร้อยแล้ว
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </div>
                            </div>
                        </v-alert>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from "vuex";
import {VDialog,VBottomSheet} from 'vuetify/lib';
import {clock} from "@/libraries/DateTime.js";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "ChatComponent",
    mixins: [MixinsHelper],
    components: {
        VDialog,
        VBottomSheet
    },
    data: function () {
        let example_array = [ //ดึงจาก chat จริง
            {
                images: [],
                is_solve: 0,
                message_id: "36",
                order_uuid: "11000010453000387",
                role: "buyer",
                text: "เทส",
                time: "2016-06-21 16:54:50",
            },
            {
                images: [],
                is_solve: 0,
                message_id: "402",
                order_uuid: "11000010453000387",
                role: "buyer",
                text: "เทส 3",
                time: "2020-04-09 03:13:31",
            },
            {
                images: [],
                is_solve: 0,
                message_id: "403",
                order_uuid: "11000010453000387",
                role: "seller",
                text: "สวัสดีค่ะ จะรีบตอบกลับนะคะ",
                time: "2020-04-09 03:14:11",
            },
            {
                images: [],
                is_solve: 0,
                message_id: "404",
                order_uuid: "11000010453000387",
                role: "seller",
                text: "ลองอีกทีสิ",
                time: "2020-04-09 03:14:32",
            }
            ,
            {
                images: ["https://t.lnwfile.com/92xvh0.jpg"],
                is_solve: 0,
                message_id: "406",
                order_uuid: "11000010453000387",
                role: "seller",
                text: "",
                time: "2020-04-09 03:15:00",
            }
            ,
            {
                images: [],
                is_solve: 0,
                message_id: "407",
                order_uuid: "11000010453000387",
                role: "seller",
                text: "zzzz",
                time: "2020-04-09 03:15:15",
            }
            ,
            {
                images: [],
                is_solve: 1,
                message_id: "408",
                order_uuid: "11000010453000387",
                role: "buyer",
                text: "ยืนยันฉันได้รับการแก้ไขปัญหาแล้ว",
                time: "2020-04-09 03:16:54",

            }
            ,
            {
                images: [],
                is_solve: 0,
                message_id: "409",
                order_uuid: "11000010453000387",
                role: "buyer",
                text: "test ต่อดูสิ มันไปที่เดิมไหม",
                time: "2020-04-09 03:20:18",
            }
            ,
            {
                images: ["https://pa.lnwfile.com/f0nauv.png"],
                is_solve: 0,
                message_id: "410",
                order_uuid: "11000010453000387",
                role: "buyer",
                text: "",
                time: "2020-04-09 03:22:07",
            }
            ,
            {
                images: [],
                is_solve: 1,
                message_id: "416",
                order_uuid: "11000010453000387",
                role: "buyer",
                text: "ยืนยันฉันได้รับการแก้ไขปัญหาแล้ว",
                time: "2020-04-09 03:33:33",
            }
            ,
            {
                images: [],
                is_solve: 0,
                message_id: "417",
                order_uuid: "11000010453000387",
                role: "buyer",
                text: "แจ้งไปเรื่อยๆ",
                time: "2020-04-09 03:34:21",
            }
            ,
            {
                images: ["https://pa.lnwfile.com/o677af.jpg"],
                is_solve: 0,
                message_id: "418",
                order_uuid: "11000010453000387",
                role: "buyer",
                text: "",
                time: "2020-04-09 03:34:38",
            }
            ,
            {
                images: ["https://pa.lnwfile.com/4vesql.jpg"],
                is_solve: 0,
                message_id: "419",
                order_uuid: "11000010453000387",
                role: "buyer",
                text: "",
                time: "2020-04-09 03:35:30",
            }
            ,
            {
                images: [],
                is_solve: 0,
                message_id: "422",
                order_uuid: "11000010453000387",
                role: "seller",
                text: "เทสมา เทสกลับไม่โกง",
                time: "2020-04-09 03:42:54",
            }, {
                images: [],
                is_solve: 0,
                message_id: "426",
                order_uuid: "11000010453000387",
                role: "supporter",
                text: "ไม่ฉลาด",
                time: "2020-04-09 13:42:26",
            }, {
                images: [],
                is_solve: 0,
                message_id: "427",
                order_uuid: "11000010453000387",
                role: "supporter",
                text: "เทสเป็น Support",
                time: "2020-04-10 18:45:20",
            }
        ];
        return {
            draft_message: '',
            new_images: [],
            is_loading: false,
            dispute_start_time: '2020-01-10 18:45:20', //ดึงจาก API
            messages: example_array,
            disputeDialog: false,
            is_dispute_example: false, //จะต้องคิดจาก computed ที่ดึง API แทน
            show_full_datetime: false
        }
    },
    watch: {},
    computed: {
        ...mapState({
            userData: state => state.user.userData,
            shopData: state => state.order.shopData,
            orderData: state => state.order.orderData,
        }),
        clock() {
            return clock;
        },
        has_dispute: function () {
            return true;
        },
        is_dispute: function () {
            return this.is_dispute_example;
        },
        buyer: function () {
            let user = {
                name: 'ลูกค้า',
                avatar: '',
            };
            if (this.userData) {
                user.name = this.userData.name;
                user.avatar = this.userData.avatar;
            }
            return user;
        }
        ,
        seller: function () {
            let user = {
                name: this.shopData.name,
                avatar: this.shopData.avatar
            };
            return user;
        }
        ,
        supporter: function () {
            let user = {
                name: 'LnwPay',
                avatar: require('@/assets/lnwpay_shield_square.png')
            }
            return user;
        }
        ,
        now_role: function () {
            //return 'supporter';
            return 'seller';
        },
        message_list: function () {
            let message_list = [];
            let message = {
                role: 'system',
                text: 'ร้องเรียนแล้ว',
                time: this.dispute_start_time,
                className: ['system']
            };
            message_list.push(message);
            let buyer = this.buyer;
            let seller = this.seller;
            let supporter = this.supporter;
            this.messages.forEach(function (msg) {
                let message = {
                    role: msg.role,
                    text: msg.text,
                    images: msg.images,
                    time: msg.time,
                    className: []
                };
                if (message.role == 'buyer') {
                    message.className.push('customer');
                    message.user_name = buyer.name;
                    message.user_avatar = buyer.avatar;
                } else if (message.role == 'seller') {
                    message.className.push('owner');
                    message.user_name = seller.name;
                    message.user_avatar = seller.avatar;
                } else if (message.role == 'supporter') {
                    message.className.push('lnwpay');
                    message.user_name = supporter.name;
                    message.user_avatar = supporter.avatar;
                }
                if (msg.text || msg.images) {
                    message_list.push(message);
                }
                if (msg.is_solve) {
                    let message = {
                        role: 'system',
                        text: '✓ เรียบร้อย',
                        time: msg.time,
                        className: ['system', 'complete']
                    };
                    message_list.push(message);
                }
            });
            this.new_images.forEach(function (new_image) {
                if (new_image.message_id) {
                    return;
                }
                let message = {
                    role: this.now_role,
                    text: 'กำลังอัพโหลด ' + new_image.progress + '%',
                    images: [],
                    time: '...',
                    className: []
                };
                if (message.role == 'buyer') {
                    message.className.push('customer');
                    message.user_name = buyer.name;
                    message.user_avatar = buyer.avatar;
                } else if (message.role == 'seller') {
                    message.className.push('owner');
                    message.user_name = seller.name;
                    message.user_avatar = seller.avatar;
                } else if (message.role == 'supporter') {
                    message.className.push('lnwpay');
                    message.user_name = supporter.name;
                    message.user_avatar = supporter.avatar;
                }
                if (new_image.error_txt) {
                    message.text = new_image.error_txt;
                    message.time = 'ผิดพลาด';
                    message.className.push('upload_error');
                }
                message_list.push(message);
            });
            return message_list;
        }
        ,
        dispute_status: function () {
            let status = {
                text: '',
                time: '',
            };
            this.message_list.forEach(function (message) {
                if (message.role == 'system') {
                    status.text = message.text;
                    status.time = message.time;
                }
            });
            return status;
        },
        isLnwPay: function () {
            return this.orderData.order_payment && this.orderData.order_payment.channel === 'lnwpay';
        },
    },
    methods: {
        confirm_dispute: function () {
            this.send_message();
            this.disputeDialog = false;
            this.is_dispute_example = true;
            this.scrollTo('#chatContainer');
        },
        confirm_complete: function () {
            this.disputeDialog = false;
            this.is_dispute_example = false;
            this.scrollTo('#lastMessage');
        },
        send_message: function () {
            let new_message = {
                images: [],
                is_solve: 0,
                message_id: "999",
                order_uuid: "11000010453000387",
                role: "buyer",
                text: this.draft_message,
                time: this.clock.now_datetime(),
            };
            this.messages.push(new_message);
            this.draft_message = '';
            return false;
        },
        send_images: function () {
            return false;
        },
        send_image: function () {
            return false;
        },
        reset_message: function () {
            this.draft_message = '';
        },
        scroll_to_bottom: function () {
            return false;
        },
        upload_init: function () {

        },
        select_image: function () {

        },
    },
    mounted: function () {
        //this.scroll_to_bottom();
    },
}
</script>