<template>
    <component :is="isDesktop?'VDialog':'VBottomSheet'"
               v-model="loginDialog"
               :persistent="isRequiredLogin"
               :max-width="isDesktop?'600px':'100%'"
               :transition="isDesktop?'dialog-transition':'dialog-bottom-transition'">
        <v-overlay :value="cpIsShowLoading"
                   opacity="0.1"
                   z-index="999">
            <v-progress-circular indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <template v-slot:activator="{on,attrs}">
            <slot name="activator"
                  :on="on"
                  :attr="attrs"></slot>
        </template>
        <v-card>
            <div id="login-wrapper">
                <div class="step">
                    <div class="loginRequired lnwme-container py-8">
                        <div class="stepContent buyerInfo">
                            <v-form ref="form_login"
                                    v-model="validLoginInfo">
                                <div class="center my-4">
                                    <img height="25"
                                         src="@/assets/lnw_new_logo.png"/>
                                    <div class="caption mt-4 grey--text">
                                        {{ $t('เพียงบัญชีเดียว ก็ใช้ได้ทุกเว็บเทพ') }}
                                    </div>
                                    <div class="caption grey--text"
                                         style="margin-top: -0.125rem;">
                                        <span style="font-size: 0.8em">{{ $t('สะดวก. รวดเร็ว. ปลอดภัย.') }}</span>
                                    </div>
                                </div>
                                <v-alert v-if="isRequiredLogin"
                                         text
                                         color="primary"
                                         type="info"
                                         class="mb-4"
                                         icon="mdi-login">
                                    {{ $t('จำเป็นต้องเข้าสู่ระบบเพื่อทำรายการสั่งซื้อ') }}
                                </v-alert>
                                <div class="just_flex mb-5">
                                    <span class="d-inline-block">{{ $t('เข้าสู่ระบบ') }}</span>
                                    <div>
                                        <v-btn text
                                               target="_blank"
                                               color="primary"
                                               :href="accountUrl('forgot_password')">{{ $t('ลืมรหัสผ่าน') }}
                                        </v-btn>
                                        <v-btn text
                                               target="_blank"
                                               color="primary"
                                               :href="accountUrl('register')">{{ $t('สมัครสมาชิก') }}
                                        </v-btn>
                                    </div>
                                </div>
                                <div class="needDiv"
                                     style="flex-grow: 1">
                                    <v-text-field v-model.trim="formMix"
                                                  v-on:change="removeNonDigitCharacters()"
                                                  :rules="mixEmailMobileRules"
                                                  clearable
                                                  :autocomplete="(isRequiredLogin)?'email':'tel'"
                                                  v-disabled-icon-focus
                                                  outlined
                                                  persistent-hint
                                                  validate-on-blur>
                                        <template v-slot:label>
                                            {{ $t('อีเมล') }}
                                            <template v-if="!isRequiredLogin">
                                                {{ $t('หรือ') }} {{ $t('เบอร์มือถือ') }}
                                            </template>
                                        </template>
                                    </v-text-field>
                                    <v-text-field v-model="password"
                                                  ref="password"
                                                  :rules="passwordRules"
                                                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                  :type="showPassword ? 'text' : 'password'"
                                                  label="รหัสผ่าน"
                                                  autocomplete="password"
                                                  v-disabled-icon-focus
                                                  @click:append="showPassword = !showPassword"
                                                  @keydown.enter="onPressEnterInPassword"
                                                  outlined>
                                        <template v-slot:message="{ message, key }">
                                            <span v-html="message"></span>
                                        </template>
                                    </v-text-field>
                                </div>
                                <div>
                                    <div @click="(!canClickEnterLoginBtn)?login_validate():''">
                                        <v-btn x-large
                                               block
                                               depressed
                                               @click="submitLoginWithPassword"
                                               :disabled="!canClickEnterLoginBtn || cpIsShowLoading"
                                               color="primary">{{ $t('ยืนยัน') }}
                                        </v-btn>
                                    </div>
                                </div>
                                <!-- Social login -->
                                <v-scroll-y-transition>
                                    <div v-if="!cpFlagIsLogin">
                                        <div class="social_login_container mt-8 flex_start"
                                             :class="(password != '')?'opacity50':''">
                                            <div class="just_flex"
                                                 style="width: 100%">
                                                <div class="social_login_text">
                                                    <template v-if="$i18n.locale == 'th'">{{ $t('หรือ') }}</template>
                                                    {{ $t('เข้าสู่ระบบด้วย') }}
                                                </div>
                                                <div class="social_login">
                                                    <div class="login_button">
                                                        <div class="facebook_login">
                                                            <button tabindex="-1"
                                                                    type="button"
                                                                    class="button button-facebook"
                                                                    @click="SocialLogin_via_Facebook('claim')">
                                                                <span class="logo"></span><span class="text">Facebook</span>
                                                            </button>
                                                        </div>
                                                        <div class="line_login">
                                                            <button tabindex="-1"
                                                                    type="button"
                                                                    class="button button-line"
                                                                    @click="SocialLogin_via_Line('claim')">
                                                                <span class="logo"></span><span class="text">LINE</span>
                                                            </button>
                                                        </div>
                                                        <div class="google_login">
                                                            <button tabindex="-1"
                                                                    type="button"
                                                                    class="button button-google"
                                                                    @click="SocialLogin_via_Google('claim')">
                                                                <span class="logo"></span><span class="text">Google</span>
                                                            </button>
                                                        </div>
                                                        <div class="apple_login">
                                                            <button tabindex="-1"
                                                                    type="button"
                                                                    class="button button-apple"
                                                                    @click="SocialLogin_via_Apple('claim')">
                                                                <span class="logo"></span><span class="text">Apple</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-scroll-y-transition>
                            </v-form>
                            <div class="mt-8 mb-4">
                                <v-btn
                                       text
                                       block
                                       x-large
                                       color="primary"
                                       @click="loginDialog = false">
                                    {{ $t('ปิดหน้าต่าง') }}
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-card>
    </component>
</template>
<script>
import DebugIndicator from "@/components/DebugTools/DebugIndicator";
import MixinsDisabledIconFocus from "@/mixins/MixinsDisabledIconFocus";
import MixinsSocialLoginMethods from "@/mixins/MixinsSocialLoginMethods";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Helper from "@/libraries/Helper";
import SiteUrl from "@/libraries/SiteUrl";
import {VBottomSheet, VDialog} from "vuetify/lib/components";
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const mobileRegexTH = /^(\+\d{1,3}[- .]?\d{9}|0[0-9]{9})$/;
const mobileRegexEN = /^(\+\d{1,3}[- ]?)?\d{4,14}$/;

export default {
    name: "ClaimOrderComponent.vue",
    components: {
        DebugIndicator,
        VDialog,
        VBottomSheet,
    },
    mixins: [MixinsDisabledIconFocus, MixinsSocialLoginMethods, MixinsHelper],
    data(){
        return {
            devel: false,
            showLoginForm: false,
            overlay: false,
            email: '',
            mobileNumber: '',
            profilePicture: '',
            isAccountExists: null,
            password: '',
            showPassword: false,
            formMix: '',
            validLoginInfo: false,
            validBuyerInfo: false,
            mixEmailMobileRules: [
                v => !!v || this.$t('กรุณากรอกข้อมูลให้ถูกต้องนะคะ'),
                v => (!v
                    || this.validateMobileNumber(v)
                    || emailRegex.test(v))
                    || this.$t('กรุณากรอกข้อมูลให้ถูกต้องนะคะ'),
            ],
            passwordRules: [
                v => !!v || this.$t('กรุณากรอกรหัสผ่านด้วยนะคะ'),
            ],
            loading: false,
            loginDialog: false
        };
    },
    computed: {
        ...mapGetters({
            cpFlagIsLogin: 'user/isLogin',
            isRequiredLogin: 'order/isRequiredLogin',
            gointer_enabled: 'order/gointer',
        }),
        ...mapState({
            orderData: state => state.order.orderData,
            shopData: state => state.order.shopData,
            userData: state => state.user.userData,
        }),
        // {------------------ store -----------------------------
        formEmail: {
            set(value){
                this.$store.commit('order/setForm', {key: 'buyerInfoEmail', value: value});
            },
            get(){
                return this.$store.state.order.form.buyerInfoEmail;
            },
        },
        formMobileNumber: {
            set(value){
                this.$store.commit('order/setForm', {key: 'buyerInfoMobileNumber', value: value});
            },
            get(){
                return this.$store.state.order.form.buyerInfoMobileNumber;
            },
        },
        // }------------------ store -----------------------------

        // {------------------ flag -----------------------------
        accountExists(){
            return this.$store.state.user.accountExists;
        },
        userData(){
            return this.$store.state.user.userData;
        },
        cpFlagAjaxBuyerInfoAccountExistsRunning(){
            return this.$store.state.order.form.ajaxBuyerInfoAccountExistsRunning;
        },
        cpFlagAjaxSaveBuyerInfoRunning(){
            return this.$store.state.order.form.ajaxSaveBuyerInfoRunning;
        },
        cpFlagAjaxAuthenticateByUsernamePasswordRunning(){
            return this.$store.state.order.form.ajaxAuthenticateByUsernamePasswordRunning;
        },
        cpIsShowLoading(){
            return this.cpFlagAjaxSaveBuyerInfoRunning
                || this.cpFlagAjaxBuyerInfoAccountExistsRunning
                || this.cpFlagAjaxAuthenticateByUsernamePasswordRunning
        },

        // }------------------ flag -----------------------------
        canClickEnterLoginBtn(){
            return !(!this.formMix
                || !this.password
                || (
                    !this.validateMobileNumber(this.formMix)
                    && !emailRegex.test(this.formMix)
                ));
        },
        buyerInfo(){
            return {
                email: this.$store.state.order.orderData.contact_email,
                mobile: this.$store.state.order.orderData.contact_mobile,
            }
        },
    },
    methods: {
        ...mapMutations({
            mutationSetForm: 'order/setForm',
            mutationSetAccountExists: 'user/setAccountExists',
        }),
        ...mapActions({
            nextStep: 'order/nextStep',
            actionSaveBuyerInfo: 'order/saveBuyerInfo',
            actionAuthenticateByUsernamePassword: 'user/authenticateByUsernamePassword',
        }),

        async submitLoginWithPassword(){
            if(this.canClickEnterLoginBtn){

                let payload;

                payload = {
                    username: this.formMix,
                    password: this.password,
                };

                const success = await this.actionAuthenticateByUsernamePassword(payload);
                if(success){
                    this.mutationSetAccountExists({});
                    this.loginDialog = false;
                    // console.log('success');
                    // await this.saveBuyerInfoFromFormMix();
                    if(this.userData){
                        // console.log(this.formMix);
                    }
                }else{
                    // console.log('wrong');
                }
                this.password = '';
            }
        },
        onlyNumberInput(){
            if(!this.formMobileNumber){
                return
            }
            const filtered = this.formMobileNumber.replace(/[^0-9+]/g, '');
            if(filtered !== this.formMobileNumber)
                this.formMobileNumber = filtered;


        },
        onPressEnterInPassword(){
            this.submitLoginWithPassword();
        },
        mobile_format(num){
            return Helper.mobile_format(num);
        },
        validateMobileNumber(number){
            if(this.orderData.lang_key == 'th'){
                return mobileRegexTH.test(number);
            }else{
                return mobileRegexEN.test(number);
            }
        },
        login_validate(){
            this.$refs.form_login.validate();
        },
        removeNonDigitCharacters: function(){
            if(!this.formMix){
                return
            }
            let num = this.formMix.replace(/[^0-9]+/g, '');
            if(this.validateMobileNumber(num)){
                this.formMix = this.formMix.replace(/[^0-9]+/g, '');
            }
        },
        accountUrl(uri){
            return SiteUrl.account(uri);
        },
    },
    mounted(){

    },
    watch: {
        loginDialog(newValue){
            // เผื่อว่ามีการกรอก email , mobile ในช่องไปก่อน แล้วไปกด login ทีหลัง
            if(newValue){
                if(!this.formMix){
                    if(this.formEmail){
                        this.formMix = this.formEmail;
                    }else if(this.formMobileNumber){
                        this.formMix = this.formMobileNumber;
                    }
                }
            }
        },
    }
}
</script>
<style scoped></style>