<script>
import Helper from "@/libraries/Helper.js";

export default {
    name: "MixinsTheOrderDetailMutual",
    computed: {
        cpOrderItems() {
            if (this.$store.state.order.orderData) {
                return this.$store.state.order.orderData.items;
            }
            return [];
        },
        cpNetPrice() {
            if (this.$store.state.order.orderData) {
                // .net คือ จำนวนเงินที่ร้านจะได้
                // .pay คือ จำนวนเงินที่ลูกค้าต้องจ่าย
                return Helper.number_format(this.$store.state.order.orderData.price.pay, 2);
            }
            return '-';
        },
        hasShippingPrice() {
            if (this.$store.state.order.orderData) {
                if(this.$store.getters['order/isWaitingShippingPrice']){
                    return false;
                }
                return !!this.$store.state.order.orderData.shipping_type_id || this.$store.state.order.orderData.is_product_no_shipping_type;
            }
            return false;
        },
    },
}
</script>

<style scoped>

</style>