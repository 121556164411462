<template>
    <PaymentProvidersWrapper
        :payment-method="myPaymentMethod"
        :hint="hint"
        :is-selected="isSelected"
    >
        <div class="input-label-small">
            {{ $t('กรอกเบอร์มือถือที่ใช้งานแอพพลิเคชั่น') }} <img alt="kplus" :src="require('@/assets/payment/mobile-bank/kplus.png')" style="width: 20px; height: 20px; vertical-align: middle; margin: 0 0 0 5px;">
        </div>
        <v-text-field
            v-model="kPlusMobileNumber"
            @keypress.enter.prevent="nothing"
            :rules="mobileRules"
            :label="$t('เบอร์มือถือ')"
            pattern="\d*"
            maxlength=10
            validate-on-blur
            outlined
            required
        ></v-text-field>

    </PaymentProvidersWrapper>
</template>

<script>
    import MixinsPaymentComponent from "./mixins/MixinsPaymentComponent.vue";
    import PaymentProvidersWrapper from "./components/PaymentProvidersWrapper.vue";
    import ProviderIcons from "./components/ProviderIcons.vue";

    export default {
        data(){
            return {
                mobileRules: [
                    v => !!v || this.$t('กรุณากรอกเบอร์มือถือด้วยนะคะ'),
                    v => (v && (v.length === 10)) || this.$t('กรุณากรอกเบอร์มือถือให้ถูกต้องนะคะ'),
                ],
            }
        },
        name: "MobileBanking",
        mixins: [MixinsPaymentComponent],
        components: {PaymentProvidersWrapper,ProviderIcons},
        computed: {
            hint() {
                return this.$t('ชำระเงินผ่านแอพพลิเคชั่น KPLUS ของธนาคารกสิกรไทย เพียงกรอกเบอร์มือถือ ระบบจะส่งการแจ้งเตือนไปยัง App และกดชำระเงินได้ทันที');
            },
            kPlusMobileNumber: {
                set(value) {
                    this.$store.commit('order/setForm', {key: 'kPlusMobileNumber', value: value});
                },
                get() {
                    return this.$store.state.order.form.kPlusMobileNumber;
                },
            },
        },
        methods: {
            nothing() {},
        },
        watch: {
            kPlusMobileNumber(newValue) {
                let input = Object.assign({}, this.value);
                if (newValue.match(/^0[1-9][0-9]{8}/)) {
                    input.mobile_banking = {
                        provider_key: 'kplus',
                        ready: true,
                    };
                }
                else {
                    input.mobile_banking = {
                        provider_key: 'kplus',
                        ready: false,
                    };
                }
                this.$emit('input', input);
            },
        },
    }
</script>

<style scoped>

</style>