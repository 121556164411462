<template>
    <div>
        <debug-indicator file-name="src/components/payment/LnwPaymentComponent.vue"/>
        <!-- ปุ่มสำหรับกด back มาเพื่อเลือก Method อื่น -->
        <template v-if="isWaitingShippingPrice">
            <v-alert class="transparent mt-4"
                     type="info"
                     text>
                {{ $t('กรุณารอร้านค้ายืนยันรายการ') }}
            </v-alert>
        </template>
        <template v-else-if="!isSelectingPaymentMethodAndProvider && _selectedMethod === 'bank_transfer'">
            <v-scroll-y-transition hide-on-leave>
                <div class="just_flex mb-3">
                    <div>{{ $t('กรุณาโอนเงินมายังเลขบัญชีธนาคารนี้') }}</div>
                    <a class="primary--text" @click="preferToChangePaymentMethod">{{ $t('เปลี่ยน') }}</a>
                </div>
            </v-scroll-y-transition>
        </template>
        <template v-else-if="!isSelectingPaymentMethodAndProvider && _selectedMethod === 'thai_qr_payment'">
            <v-scroll-y-transition hide-on-leave>
                <div class="just_flex mb-3">
                    <div>{{ $t('กรุณา Scan QR เพื่อโอนเงินมายังบัญชีธนาคารนี้') }}</div>
                    <a class="primary--text" @click="preferToChangePaymentMethod">{{ $t('เปลี่ยน') }}</a>
                </div>
            </v-scroll-y-transition>
        </template>

        <!-- กล่องแสดง การเลือก skip ช่องทางชำระเงินแล้ว -->
        <template v-if="isSkippedPaymentMethod">

        </template>
        <!-- กล่อง Accordion สำหรับเลือก Method&Provider :: แสดงเฉพาะกรณีที่ลูกค้าต้องการเปลี่ยน หรือยังไม่ได้เลือก -->
        <template v-else-if="isSelectingPaymentMethodAndProvider">
            <v-list-item-group top>
                <v-radio-group style="margin: 5px 0 0;"
                               :rules="[v => !!v || $t('กรุณาเลือกวิธีชำระเงินด้วยนะคะ')]"
                               v-model="_selectedMethod"
                               hide-details="auto">
                    <v-card class="mx-auto has-label allChannel"
                            flat>
                        <!-- @todo แสดง recent payment methods ตรงนี้ -->
                        <!-- list ช่องทางชำระเงินแต่ละ Method -->
                        <fieldset>
                            <!-- @todo รอแสดง recent payment methods แล้วค่อยแสดงหัวข้อนี้ -->
                            <!--                            <legend v-if="!_selectedMethod" style="width: 80px; margin-left: 10px;"><span></span></legend>-->
                            <!--                            <label v-if="!_selectedMethod" class="v-label-for-card">ช่องทางทั้งหมด</label>-->
                            <v-list top>
                                <div v-for="(method, index) in paymentMethods"
                                     :id="method.key"
                                     :key="method.key">
                                    <label v-show="checkUsablePaymentMethod(method)">
                                        <v-list-item v-if="!_selectedMethod ||_selectedMethod === method.key"
                                                     :class="{
                                                     selected: (_selectedMethod === method.key)}">
                                            <v-list-item-action>
                                                <v-radio :value="method.key"></v-radio>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <div class="paymentTitle">
                                                        <span>{{ $t(method.lang) }}</span>
                                                        <ProviderIcons v-if="!(_selectedMethod === method.key)"
                                                                       :payment-method="method"></ProviderIcons>
                                                    </div>
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <v-slide-y-transition hide-on-leave>
                                                        <template v-if="method.key === 'bank_transfer'">
                                                            <BankTransfer :payment-profiles="paymentProfiles"
                                                                          :payment-methods="paymentMethods"
                                                                          payment-method="bank_transfer"
                                                                          v-model="inputValue"/>
                                                        </template>
                                                        <template v-else-if="method.key === 'thai_qr_payment'">
                                                            <ThaiQrPayment :payment-profiles="paymentProfiles"
                                                                           :payment-methods="paymentMethods"
                                                                           payment-method="thai_qr_payment"
                                                                           v-model="inputValue"/>
                                                        </template>
                                                        <template v-else-if="method.key === 'counter_payment'">
                                                            <CounterPayment :payment-profiles="paymentProfiles"
                                                                            :payment-methods="paymentMethods"
                                                                            payment-method="counter_payment"
                                                                            v-model="inputValue"/>
                                                        </template>
                                                        <template v-else-if="method.key === 'credit_card'">
                                                            <CreditCard v-bind="Object.assign({'payment-method': 'credit_card'}, subComponentProps)"
                                                                        v-model="inputValue"/>
                                                        </template>
                                                        <template v-else-if="method.key === 'e_wallet'">
                                                            <EWallet :payment-profiles="paymentProfiles"
                                                                     :payment-methods="paymentMethods"
                                                                     payment-method="e_wallet"
                                                                     v-model="inputValue"/>
                                                        </template>
                                                        <template v-else-if="method.key === 'internet_banking'">
                                                            <!--suppress RequiredAttributes, JSUnresolvedVariable -->
                                                            <InternetBanking :payment-profiles="paymentProfiles"
                                                                             :payment-methods="paymentMethods"
                                                                             payment-method="internet_banking"
                                                                             v-model="inputValue"/>
                                                        </template>
                                                        <template v-else-if="method.key === 'mobile_banking'">
                                                            <MobileBanking :payment-profiles="paymentProfiles"
                                                                           :payment-methods="paymentMethods"
                                                                           payment-method="mobile_banking"
                                                                           v-model="inputValue"/>
                                                        </template>
                                                    </v-slide-y-transition>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </label>
                                    <v-divider v-if="( (_selectedMethod && index === paymentMethods.length -1) || (!_selectedMethod && index !== paymentMethods.length -1)) && checkUsablePaymentMethod(method)"></v-divider>
                                </div>
                                <div v-if="_selectedMethod">
                                    <label @click="preferToChangePaymentMethod">
                                        <v-list-item>
                                            <v-list-item-action>
                                                <v-icon small style="left: 5px;">mdi-chevron-down</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <span style="font-weight: normal;">{{ $t('แสดงวิธีการชำระเงินทั้งหมด') }}</span>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </label>
                                </div>
                            </v-list>
                        </fieldset>
                        <v-btn v-if="!showAllPaymentMethods"
                               @click="showAllPaymentMethods=true"
                               x-large
                               block
                               text
                               depressed
                               color="primary"
                               class="mb-6">
                            {{ $t('แสดงวิธีการชำระเงินทั้งหมด') }}
                            <v-icon small>mdi-chevron-down</v-icon>
                        </v-btn>
                    </v-card>
                </v-radio-group>
            </v-list-item-group>
        </template>
        <!-- กล่องแสดง ช่องทางชำระเงิน ที่เลือกเอาไว้แล้ว -->
        <template v-else-if="!isWaitingShippingPrice">
            <selected-payment-method-viewer :input-value="inputValue"
                                            :payment-methods="paymentMethods"></selected-payment-method-viewer>
        </template>

    </div>
</template>
<script>
import DebugIndicator from "@/components/DebugTools/DebugIndicator.vue";
import CreditCard from "./components/LnwPaymentComponent/CreditCard.vue";
import BankTransfer from "./components/LnwPaymentComponent/BankTransfer.vue";
import ThaiQrPayment from "./components/LnwPaymentComponent/ThaiQrPayment.vue";
import CounterPayment from "./components/LnwPaymentComponent/CounterPayment.vue";
import EWallet from "./components/LnwPaymentComponent/EWallet.vue";
import InternetBanking from "./components/LnwPaymentComponent/InternetBanking.vue";
import MobileBanking from "./components/LnwPaymentComponent/MobileBanking.vue";
import InformPaymentDialog from "@/components/Order/components/PaymentMethod/InformPaymentDialog.vue";
import {mapGetters, mapMutations} from "vuex";
import ProviderIcons from "./components/LnwPaymentComponent/components/ProviderIcons.vue";
import SelectedPaymentMethodViewer
    from "@/components/Order/components/PaymentMethod/components/LnwPaymentComponent/SelectedPaymentMethodViewer.vue";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "LnwPaymentComponent",
    mixins : [MixinsHelper],
    components: {
        DebugIndicator,
        MobileBanking,
        InternetBanking,
        EWallet,
        CounterPayment,
        ThaiQrPayment,
        BankTransfer,
        CreditCard,
        ProviderIcons,
        SelectedPaymentMethodViewer,
        InformPaymentDialog,
    },
    props: {
        paymentMethods: {
            type: Array,
            required: true,
        },
        paymentProfiles: {
            type: Array,
            required: true,
        },
        value: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            // showAllPaymentMethods : แสดงช่องทางชำระเงินทั้งหมดมั้ย (default จะแสดงเฉพาะช่องทางที่เคยเลือกใช้งาน)
            showAllPaymentMethods: !(this.paymentProfiles.length > 0),
        }
    },
    computed: {
        ...mapGetters({
            cpFlagIsLogin: 'user/isLogin',
            isBankTransferPaymentProvider: 'order/isBankTransferPaymentProvider',
            getShopFileNode: 'order/getShopFileNode',
            bankPaymentMethodBanks: 'order/bankPaymentMethodDropdownOptions',
            orderData: 'order/order',
            stepMapping: 'order/stepMapping',
            isSelectingPaymentMethodAndProvider: 'order/isSelectingPaymentMethodAndProvider',
            isPreferToChangePaymentMethod: 'order/isPreferToChangePaymentMethod',
            isWaitingShippingPrice: 'order/isWaitingShippingPrice',
        }),
        isSkippedPaymentMethod(){
            return this._selectedMethod === 'skip';
        },
        bankPaymentMethodBanksForDisplay() {
            const list = [];
            for (let o of this.bankPaymentMethodBanks) {
                if (o.key === this.orderData.payment_provider) {
                    list.push(o);
                    break;
                }
            }

            if (list.length === 0)
                return this.bankPaymentMethodBanks;

            return list;
        },
        _selectedMethod: {
            get: function () {
                return (this.value && this.value.selectedMethod) ? this.value.selectedMethod : null;
            },
            set: function (newValue) {
                this.changePaymentMethod(newValue);
            }
        },
        subComponentProps() {
            return {
                'payment-methods': this.paymentMethods,
                'payment-profiles': this.paymentProfiles,
            };
        },
        inputValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                newValue.ready = !!(!!this._selectedMethod && newValue[this._selectedMethod] && newValue[this._selectedMethod].ready);
                this.$emit('input', newValue);
            },
        },
        selectedPaymentMethodObject() {
            for (let method of this.paymentMethods) {
                if (this._selectedMethod === method.key) {
                    return method;
                }
            }

            return null;
        },
        orderPaymentProviderDisplayName() {
            let payment_provider;

            if (this.isSelectingPaymentMethodAndProvider) {
                if (this.value.selectedMethod) {
                    if (this.value[this.value.selectedMethod]
                        && this.value[this.value.selectedMethod].provider_key) {
                        payment_provider = this.value[this.value.selectedMethod].provider_key;
                    }
                }
            } else {
                payment_provider = this.orderData.payment_provider
            }

            if (!payment_provider) {
                if (this.value.selectedMethod) {
                    for (const m of this.orderData.payment_methods)
                        if (m.key === this.value.selectedMethod)
                            return m.lang;
                }
                return '';
            }

            for (const method of this.orderData.payment_methods) {

                if (method.key === payment_provider)
                    return method.lang;


                if (Array.isArray(method.providers)) {
                    for (const provider of method.providers) {
                        if (provider.key === payment_provider) {
                            // ถ้ากำลังเลือก Method/Provider จะแสดงแค่ ชื่อ Method
                            /*if (this.isPreferToChangePaymentMethod) {
                                return method.lang;
                            }
                            else {
                                return `${method.lang} ${provider.lang}`;
                            }*/
                            //ให้ return เฉพาะ method ครับ เพราะจุดนี้จะแสดงเพราะเค้าเลือก method มาก่อน และกำลังจะเลือก provider
                            return method.lang;
                        }
                    }
                }

            }

            return '';
        },
    },
    methods: {
        ...mapMutations({
            storeActionSetForm: 'order/setForm',
        }),
        offsetTop(el) {
            var rect = el.getBoundingClientRect(),
                scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            return rect.top + scrollTop
        },
        changePaymentMethod(paymentMethod) {
            let newState = typeof this.value === 'object' ? Object.assign({}, this.value) : {};
            newState.selectedMethod = paymentMethod;

            if (paymentMethod) { // ต้อง if ครอบไว้เพราะ มันมีกรณีที่เราจะส่ง null เข้ามา เพื่อเคลียว่ากำลังเลือก paymentMethod ไหนอยู่

                newState.ready = !!(newState[newState.selectedMethod] && newState[newState.selectedMethod].ready);

                // ref: 991bfef0-15c7-46b5-9c51-b76777dad225
                // ถ้า paymentMethod นั้นมี provider เดียว ให้ auto select เลยครับ
                if (!newState[newState.selectedMethod]) {
                    newState[newState.selectedMethod] = {};
                    for (const payment_method of this.orderData.payment_methods) {
                        if (payment_method.key === paymentMethod) {
                            if (Array.isArray(payment_method.providers) && payment_method.providers.length === 1) {
                                newState[newState.selectedMethod].provider_key = payment_method.providers[0].key;
                            }
                            break;
                        }
                    }
                }
            }else{
                // กรณีกดเลือกเปลี่ยนวิธีให้เปลี่ยน ready ด้วย
                newState.ready = false;
            }

            let that = this;
            setTimeout(function () {
                let methodDiv = document.getElementById(newState.selectedMethod);
                if (methodDiv) {
                    let offsetHeight = (document.getElementById('infoFixedTop').offsetHeight === 0 && that.isDesktop) ? 70 : document.getElementById('infoFixedTop').offsetHeight;
                    let offsetHeightBanner = 0;
                    if (document.getElementById('bannerStatus')) {
                        offsetHeightBanner = document.getElementById('bannerStatus').offsetHeight;
                    }
                    let offsetTotal = (that.isDesktop) ? offsetHeightBanner : offsetHeight + offsetHeightBanner;
                    if (that.offsetTop(methodDiv) < window.scrollY + offsetHeight) {
                        that.$vuetify.goTo('#' + newState.selectedMethod, {offset: offsetTotal});
                    }
                }
            }, 300);
            this.$emit('input', newState);
        },
        checkUsablePaymentMethod(payment_method) {
            return !payment_method.is_lnwpay // ถ้าไม่ใช่ lnwpay
                // ถ้าเป็น lnwpay จะใช้ได้เฉพาะ ที่ราคา order อยู่ในช่วงที่กำหนด
                || (
                    payment_method.enable
                    && this.$store.state.order.orderData.price.pay >= payment_method.minimum_amount
                    && this.$store.state.order.orderData.price.pay <= payment_method.maximum_amount
                );
        },
        preferToChangePaymentMethod() {
            if(this.checkNoPermissionAndPopup()) return;
            this._selectedMethod = null;
            this.storeActionSetForm({
                key: 'isPreferToChangePaymentMethod',
                value: true,
            });
            this.scrollTo('#step' + this.stepMapping.paymentMethod);
        },
    },
}
</script>
<style scoped></style>