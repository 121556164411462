<template>
    <component :is="isDesktop?'VDialog':'VBottomSheet'"
               v-model="confirmOrderDialog"
               persistent
               scrollable
               :max-width="isDesktop?'400px':'100%'"
               >
        <template v-slot:activator="{on,attrs}">
            <slot name="activator"
                  :on="on"
                  :attr="attrs"></slot>
        </template>
        <v-card class="confirmOrderDialog">
            <v-card-title>
                <div>{{ $t('การยืนยันว่าได้รับสินค้าแล้ว') }}</div>
                <v-spacer></v-spacer>
                <v-btn icon
                       @click="confirmOrderDialog = false">
                    <v-icon color="primary">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="px-4">
                <!--                <div class="mb-4 grey&#45;&#45;text">-->
                <!--                    เพื่อผลประโยชน์สูงสุดของคุณ กรุณาอ่านรายละเอียดก่อนการยืนยัน-->
                <!--                </div>-->
                <v-alert type="info"
                         color="primary"
                         dense
                         text
                         class="px-4"
                         style="font-size: 0.8rem;">
                    <b>{{ $t('ขอบคุณ') }}</b> {{ $t('ที่คุณช่วยลดขั้นตอนการดำเนินการต่างๆของทีมงานและยังช่วยให้ร้านค้าได้รับยอดเงินเร็วขึ้นด้วย') }}
                </v-alert>
                <v-card elevation="24">
                    <v-list dense>
                        <v-list-item>
                            <v-list-item-icon>
                                <img class="mr-1"
                                     style="width: 18px; height: 18px;"
                                     :src="require('@/assets/order_status/completed.png')">
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title style="white-space: normal; overflow: visible;">
                                    <span>{{ $t('ทั้งนี้คุณจะย้อนกลับการยืนยันนี้ไม่ได้') }}</span>
                                    <v-tooltip class="d-inline-block mr-1"
                                               top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small
                                                    v-on="on">
                                                mdi-help-circle-outline
                                            </v-icon>
                                        </template>
                                        {{ $t('รายการสั่งซื้อจะเป็นสถานะ เรียบร้อย ทันที') }}
                                    </v-tooltip>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="pt-0">
                            <v-list-item-icon>
                                <img class="mr-1"
                                     style="width: 18px; height: 18px;"
                                     :src="require('@/assets/order_status/suspended.png')">
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title style="white-space: normal; overflow: visible;">
                                    <span>{{ $t('แจ้งข้อร้องเรียนหรือปัญหาไม่ได้อีก') }}</span>
                                    <v-tooltip class="d-inline-block mr-1"
                                               top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small
                                                    v-on="on">
                                                mdi-help-circle-outline
                                            </v-icon>
                                        </template>
                                        {{ $t('เพื่อความแน่ใจ กรุณาตรวจสอบสินค้าทุกชิ้นอีกครั้ง') }}
                                    </v-tooltip>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="pt-0">
                            <v-list-item-icon>
                                <img class="mr-1"
                                     style="width: 18px; height: 18px;"
                                     :src="require('@/assets/order_status/refund.png')">
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title style="white-space: normal; overflow: visible;">
                                    <span>{{ $t('ไม่สามารถขอคืนเงินได้แล้ว') }}</span>
                                    <v-tooltip class="d-inline-block mr-1"
                                               top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small
                                                    v-on="on">
                                                mdi-help-circle-outline
                                            </v-icon>
                                        </template>
                                        {{ $t('LnwPay จะโอนเงินที่คุณได้ชำระค่ารายการสั่งซื้อนี้ให้กับร้านค้าทันที') }}
                                    </v-tooltip>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-card-text>
            <v-card-actions class="pb-4">
                <!--                    <v-btn v-if="isDesktop"-->
                <!--                           class=""-->
                <!--                           @click="confirmOrderDialog = false"-->
                <!--                           x-large-->
                <!--                           text>-->
                <!--                        ยังไม่ใช่ตอนนี้-->
                <!--                    </v-btn>-->
                <v-btn :class="(!isDesktop)?'w-100':''"
                       x-large
                       block
                       color="primary"
                       @click="onClickConfirmReceived">
                    {{ $t('ฉันยืนยันว่าได้รับสินค้าเรียบร้อยแล้ว') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </component>
</template>
<script>
import {VDialog, VBottomSheet} from 'vuetify/lib';
import DebugIndicator from "@/components/DebugTools/DebugIndicator";
import {mapActions, mapGetters} from "vuex";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "ConfirmOrderComponent",
    data() {
        return {
            confirmOrderDialog: false,
        }
    },
    mixins: [
        MixinsHelper,
    ],
    components: {
        DebugIndicator,
        VDialog,
        VBottomSheet
    },
    computed: {
        ...mapGetters({
            canConfirmReceived: 'order/canConfirmReceived',
            orderUUID: 'order/orderUUID',
        }),
    },
    mounted(){
        // console.log(this.$route.query.inform);
        if(this.$route.query.confirm){
            this.confirmOrderDialog = true;
        }
    },
    methods: {
        ...mapActions({
            orderConfirmReceived: 'lnwpay/orderConfirmReceived',
        }),

        onClickConfirmReceived() {
            const order_uuid = this.orderUUID;
            this.orderConfirmReceived({order_uuid});
        },
    },
}
</script>
<style scoped></style>