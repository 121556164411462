
<script>
    import MixinsOtherOnlinePaymentComponent from "./mixins/MixinsOtherOnlinePaymentComponent.vue";

    export default {
        name: "InternetBanking",
        mixins: [MixinsOtherOnlinePaymentComponent],
        computed: {
            hint() {
                return this.$t('เพียงเข้าสู่ระบบบริการ i-Banking, e-Banking ของธนาคาร และกดชำระเงินได้ทันที');
            },
        },
    }
</script>

<style scoped>

</style>