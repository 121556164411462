import { render, staticRenderFns } from "./ShippingMethod.vue?vue&type=template&id=39570530&scoped=true"
import script from "./ShippingMethod.vue?vue&type=script&lang=js"
export * from "./ShippingMethod.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39570530",
  null
  
)

export default component.exports