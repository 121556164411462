<script>
    import MixinsOtherOnlinePaymentComponent from "./mixins/MixinsOtherOnlinePaymentComponent.vue";

    export default {
        name: "EWallet",
        mixins: [MixinsOtherOnlinePaymentComponent],
        computed: {
            hint() {
                return this.$t('ชำระเงินด้วยการตัดเงินจาก e-Wallet');
            },
        }
    }
</script>

<style scoped>

</style>