<template>
    <div>
        <payment-providers-wrapper :hint="hint"
                                   :is-selected="isSelected"
                                   :payment-method="myPaymentMethod">
            <div class="input-label-small just_flex flex_wrap">
                <span>{{ $t('กรอกข้อมูลบัตรของคุณ') }}</span>
                <div>
                    <v-icon small class="mr-1"
                            color="green">mdi-shield-check
                    </v-icon>
                    <span class="text-caption small green--text">{{ $t('ชำระเงินอย่างปลอดภัย') }}</span>
                </div>
            </div>

            <v-alert v-show="hasRememberedCreditCards && fillingNewCreditCard"
                     text
                     type="info"
                     class="mb-2"
                     color="#666">
                <div class="just_flex">
                    <span>{{ $t('คุณกำลังเพิ่มบัตรใหม่') }}</span>
                    <a class="primary--text"
                       @click="fillingNewCreditCard = false">{{ $t('เปลี่ยน') }}</a>
                </div>

            </v-alert>

            <template v-if="!fillingNewCreditCard && hasRememberedCreditCards">
                <v-radio-group dense
                               :rules="[v => !!v || $t('กรุณาเลือกบัตรด้วยนะคะ')]"
                               v-model="selectedTokenId"
                               hide-details="auto">
                    <v-card outlined>
                        <v-list-item-group>
                            <div v-for="(token, index) in creditCardTokenList" :key="token.token_id">
                                <label>
                                    <v-list-item :class="{ 'selected': (token.token_id === selectedTokenId)}">
                                        <v-list-item-action>
                                            <v-radio :value="token.token_id"></v-radio>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <b class="text-caption">**** **** ****</b>
                                                <b>&nbsp;{{ token.last_digits }}</b>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <div class="usedMethodIcon cardType" :type="token.brand.toLowerCase()"></div>
                                        </v-list-item-action>
                                    </v-list-item>
                                </label>
                                <v-divider v-if="index !== creditCardTokenList.length -1"></v-divider>
                            </div>
                        </v-list-item-group>
                    </v-card>
                </v-radio-group>
                <div class="just_flex_start credit_div">
                    <v-text-field v-model="cvv"
                                  :rules="rules_for_ccv"
                                  :maxlength="4"
                                  autocomplete="cc-csc"
                                  :label="$t('รหัสหลังบัตร')"
                                  outlined
                                  required
                                  validate-on-blur
                                  style="max-width: 150px;"
                                  hide-details="auto"
                                  class="py-2 mb-4 appearance-none"
                                  pattern="\d*">
                        <template v-slot:append>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                                </template>
                                {{ $t('ตัวเลข 3 หลักที่อยู่หลังบัตรเครดิตของคุณ') }}
                            </v-tooltip>
                        </template>
                    </v-text-field>
                    <a class="primary--text mt-0"
                       @click="fillingNewCreditCard = true">{{ $t('เพิ่มบัตรใหม่') }}</a>
                </div>
            </template>
            <!-- เพิ่มบัตรใหม่ -->
            <template v-else>
                <v-text-field :value="formatCardNumber"
                              @input="updateCardNumber"
                              :rules="rules_for_number"
                              :maxlength="19"
                              autocomplete="cc-number"
                              pattern="\d*"
                              :label="$t('หมายเลขบัตร')"
                              outlined
                              validate-on-blur
                              required
                              hide-details="auto"
                              class="py-2 appearance-none">
                    <template v-slot:append>
                        <div class="cardType"
                             :type="cardType"></div>
                    </template>
                </v-text-field>
                <v-text-field v-model="name"
                              :rules="rules_for_name"
                              validate-on-blur
                              :label="$t('ชื่อบนบัตร')"
                              autocomplete="cc-name"
                              outlined
                              required
                              hide-details="auto"
                              class="py-2"></v-text-field>
                <div class="flex_align_start credit_div" style="gap: 10px 15px">
                    <v-text-field v-model="exp"
                                  :rules="rules_for_exp"
                                  :maxlength="5"
                                  :label="$t('วันหมดอายุบัตร')"
                                  persistent-hint
                                  autocomplete="cc-exp"
                                  outlined
                                  hide-details="auto"
                                  style="flex-basis: 160px; flex-grow: 0"
                                  @keypress="keypressExpireDate"
                                  pattern="\d*"
                                  class="py-2 appearance-none">
                        <template v-slot:append>
                            <div :class="isDesktop?'text-sm-body-2':'text-caption'" class="grey--text">MM/YY</div>
                        </template>
                    </v-text-field>
                    <v-text-field v-model="cvv"
                                  :rules="rules_for_ccv"
                                  :maxlength="4"
                                  validate-on-blur
                                  autocomplete="cc-csc"
                                  :label="$t('รหัสหลังบัตร')"
                                  outlined
                                  required
                                  style="flex-basis: 150px; flex-grow: 0"
                                  hide-details="auto"
                                  class="py-2 appearance-none"
                                  pattern="\d*">
                        <template v-slot:append>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                                </template>
                                {{ $t('ตัวเลข 3 หลักที่อยู่หลังบัตรเครดิตของคุณ') }}
                            </v-tooltip>
                        </template>
                    </v-text-field>
                </div>
                <template v-if="isLogin">
                    <v-switch hide-details class="mt-4 mb-0" v-model="remember" :label="$t('บันทึกบัตรนี้ไว้ใช้ภายหลัง')"></v-switch>
                    <v-divider style="display: block" class="my-4"></v-divider>
                </template>
                <template v-else>
                    <div class="my-4"></div>
                </template>
            </template>
        </payment-providers-wrapper>
    </div>
</template>
<script>
import MixinsPaymentComponent from "./mixins/MixinsPaymentComponent.vue";
import PaymentProvidersWrapper from "./components/PaymentProvidersWrapper.vue";

import ValidationRules from "../../../../../../libraries/ValidationRules.js";
import getCreditCardType from "../../../../../../libraries/getCreditCardType.js";
import {mapGetters} from "vuex";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper.vue";

export default {
    name: "CreditCard",
    mixins: [MixinsPaymentComponent,MixinsHelper],
    components: {
        PaymentProvidersWrapper,
    },
    data() {
        return {
            fillingNewCreditCard: null,

            cardType: '',
            number: this.value && this.value[this.paymentMethod] && this.value[this.paymentMethod].number ? this.value[this.paymentMethod].number : '',
            name: this.value && this.value[this.paymentMethod] && this.value[this.paymentMethod].name ? this.value[this.paymentMethod].name : '',
            exp: this.value && this.value[this.paymentMethod] && this.value[this.paymentMethod].exp ? this.value[this.paymentMethod].exp : '',
            cvv: this.value && this.value[this.paymentMethod] && this.value[this.paymentMethod].cvv ? this.value[this.paymentMethod].cvv : '',
            remember: this.value && this.value[this.paymentMethod] && this.value[this.paymentMethod].remember ? this.value[this.paymentMethod].remember : false,

            selectedTokenId: null,
        };
    },
    computed: {
        ...mapGetters({
            isLogin: 'user/isLogin',
            isRealMall: 'order/isRealMall',
        }),
        formatCardNumber(){
            return this.number ? this.number.match(/.{1,4}/g).join(' ') : '';
        },
        creditCardTokenList() {
            // return [
            //     { "token_id": "7204", "last_digits": "0585", "brand": "Visa" },
            //     { "token_id": "7205", "last_digits": "0585", "brand": "Visa" },
            //     { "token_id": "7206", "last_digits": "0585", "brand": "Mastercard" }
            // ];
            return this.$store.state.lnwpay?.creditCardTokenList;
        },
        countRememberedCreditCards() {
            if (this.creditCardTokenList && Array.isArray(this.creditCardTokenList))
                return this.creditCardTokenList.length;

            return 0;
        },
        hasRememberedCreditCards() {
            return this.countRememberedCreditCards > 0;
        },
        hint() {
            return this.$t('สะดวกสบายจ่ายผ่านบัตรเครดิตหรือเดบิตทุกธนาคารที่มี VISA, MASTERCARD หรือ JCB');
        },
        rules_for_number: function() {
            let rules = [];
            rules.push(ValidationRules.common.required(this.$t('กรุณาระบุหมายเลขบัตร')));
            rules.push(ValidationRules.custom.credit_card_number(this.number));
            return rules;
        },
        rules_for_name: function() {
            let rules = [];
            rules.push(ValidationRules.common.required(this.$t('กรุณาระบุชื่อบนบัตร')));
            return rules;
        },
        rules_for_exp: function() {
            let rules = [];
            rules.push(ValidationRules.common.required(this.$t('กรุณาระบุวันหมดอายุบัตร')));
            rules.push(ValidationRules.custom.credit_card_exp(this.exp));
            return rules;
        },
        rules_for_ccv: function() {
            let rules = [];
            rules.push(ValidationRules.common.required(this.$t('กรุณารหัสหลังบัตร')));
            rules.push(ValidationRules.custom.rules_for_ccv(this.cvv));
            return rules;
        },
    },
    methods: {
        updateCardNumber(val){
            this.number = val.replace(/ /g,'');
        },
        changeHandler() {
            let input = Object.assign({}, this.value);
            input[this.paymentMethod] = Object.assign({}, this.$data);
            input[this.paymentMethod].ready = false;

            // กรณีเพิ่มบัตรใหม่
            if (this.fillingNewCreditCard) {
                if( input[this.paymentMethod].number
                    && input[this.paymentMethod].name
                    && input[this.paymentMethod].exp
                    && input[this.paymentMethod].cvv
                ){
                    input[this.paymentMethod].ready = true;
                }
            } else { // กรณีเลือกบัตรเดิม
                if( input[this.paymentMethod].selectedTokenId
                    && input[this.paymentMethod].cvv
                ){
                    input[this.paymentMethod].ready = true;
                }
            }
            this.$emit('input', input);
            this.cardType = getCreditCardType(input[this.paymentMethod].number).toLowerCase();
        },
        keypressExpireDate(event) {
            var charCode = event.key.charCodeAt(0);
            // Filtered to numeric keys + slash
            if(charCode >= 47 && charCode <= 57) {
                // Extra handing around the case where the / is at the front of a highlighted block
                if(event.target.selectionStart === 2) {
                    if(event.target.selectionEnd !== 2) {
                        // Bumps the highlighted section off of the / if so.
                        event.target.selectionStart += 1
                    } else if (charCode != 47) {
                        // Adds the slash before handling the third character.
                        event.target.value += '/'
                    }
                }
            } else {
                // Whitelisting for control keys
                switch(charCode) {
                    case 8:
                    case 9:
                        break;
                    default:
                        event.preventDefault()
                }
            }
        },
        keypressInNumber(evt) {
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
                return false;
            } else {
                return true;
            }
        },
    },
    mounted() {
        const input = Object.assign({}, this.value === null ? {} : this.value);
        input.credit_card = {
            provider_key: 'credit_card',
            ready: false,
        };
        this.$emit('input', input);

        // if it has no card then must fill new card
        this.fillingNewCreditCard = !this.hasRememberedCreditCards;

        // ถ้ามีบัตรที่จำบัตรเดียวให้เลือกเลย
        if (this.hasRememberedCreditCards && this.countRememberedCreditCards === 1) {
            this.selectedTokenId = this.creditCardTokenList[0].token_id;
        }
    },
    watch: {
        number: 'changeHandler',
        name: 'changeHandler',
        exp: 'changeHandler',
        cvv: 'changeHandler',
        remember: 'changeHandler',
        fillingNewCreditCard: 'changeHandler',
        selectedTokenId: 'changeHandler',
        hasRememberedCreditCards(newValue)  {
            if (newValue)
                this.fillingNewCreditCard = false;
            if (this.countRememberedCreditCards === 1) {
                this.selectedTokenId = this.creditCardTokenList[0].token_id;
            }
        },
    },
}
</script>
<style scoped></style>