<script>
    import {mapActions} from 'vuex'
    export default {
        name: "MixinsFormCouponCode",
        computed: {
            form_coupon_code: {
                set(value) {
                    this.$store.commit('order/setForm', {key: 'coupon_code', value: value});
                },
                get() {
                    return this.$store.state.order.form.coupon_code;
                },
            },
        },
        methods: {
            ...mapActions({
                setCouponCode: 'order/setCouponCode',
                removeCouponCode: 'order/removeCouponCode',
            }),
            applyCouponCode() {
                this.setCouponCode({coupon_code: this.form_coupon_code})
                    .then(() => {
                        this.form_coupon_code = '';
                    });
            },
            async applyCouponId(coupon_id) {
                return await this.setCouponCode({coupon_id});
            },
            removeCoupon(coupon) {
                if (coupon.code)
                    this.removeCouponCode({coupon_code: coupon.code});
                else
                    this.removeCouponCode({coupon_id: coupon.id});
            },
        },
    }
</script>