<script>
    import MixinsOtherOnlinePaymentComponent from "./mixins/MixinsOtherOnlinePaymentComponent.vue";

    export default {
        name: "CounterPayment",
        mixins: [MixinsOtherOnlinePaymentComponent],
        computed: {
            hint() {
                return this.$t('พิมพ์ใบชำระเงินหรือจดรหัสเพื่อไปชำระที่ 7-11, mPay station หรือ Airpay Counter');
            },
        }
    }
</script>

<style scoped>

</style>