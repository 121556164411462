import i18n from "@/i18n.js";

const message_require_this_item = i18n.t("จำเป็นต้องระบุ");

export default {

    common: {
        required(error_message = message_require_this_item) {
            return (value) => {
                if (value.length === 0)
                    return error_message;
                return true;
            }
        }
    },

    custom: {

        credit_card_number(input) {
            if (input.length !== 16)
                return i18n.t('กรอกเลขบัตร 16 หลัก');
            if (!input.match(/^[0-9]{16}$/))
                return i18n.t('กรอกเฉพาะเลข ไม่ต้องใส่เว้นวรรค หรือ -');

            return true;
        },

        rules_for_ccv(input) {
            if (input.length < 3)
                return message_require_this_item;
            if (!input.match(/^[\d]{3,4}$/))
                return i18n.t('รูปแบบไม่ถูกต้อง');
            return true;
        },

        credit_card_exp(input) {
            const message_invalid_format = i18n.t('รูปแบบวันหมดอายุไม่ถูกต้อง กรุณากรอกในรูปแบบนี้ MM/YY');

            // ต้องมีเฉพาะตัวเลข หรือ / เท่านั้น
            let number_of_slashes = (input.match(/\//g) || []).length;
            if (input.match(/[^0-9/]/)
                || number_of_slashes > 1
                || (number_of_slashes === 1 && !input.match(/^[0-9]{2}\//))
            )
                return message_invalid_format;

            // ถ้ายังกรอกไม่ครบก็แสดงประมาณนี้ไปก่อน ไม่อยากขึ้นว่าเข้ากรอกผิดรูปแบบตั้งแต่แรกๆ
            if (
                input.length < 4
                || (input.length === 4 && input.match(/^[0-9]{2}\/?[0-9]$/))
            )
                return message_require_this_item;

            let matches = input.match(/^([\d]{2})\/?([\d]{2})$/)

            if (
                !matches
                || !(matches[1]>=1 && matches[1]<=12)
            )
                return message_invalid_format;

            const now = new Date();
            let Current_YYMM = '';
            Current_YYMM += now.getFullYear() % 100;
            if ((now.getMonth()+'').length === 1){
                Current_YYMM += '0' + now.getMonth();
            }
            else{
                Current_YYMM += now.getMonth();
            }
            if ((matches[2]+matches[1]) < Current_YYMM){
                return i18n.t('วันหมดอายุบัตรผ่านมาแล้ว');
            }
            return true;
        },

    }

}