<template>
    <div class="paymentChannel">
        <PaymentProvidersWrapper :payment-method="myPaymentMethod"
                                 :is-selected="isSelected"
                                 :hint="hint">
            <div v-if="displayingProviders.length > 1"
                 class="input-label-small">{{ $t('เลือกช่องทางชำระ') }} :
            </div>
            <div v-else
                 class="pb-2"></div>
            <v-radio-group dense
                           v-model="selectedProvider"
                           :rules="[v => !!v || $t('กรุณาเลือกช่องทางด้วยนะคะ')]"
                           hide-details="auto">
                <v-card outlined>
                    <v-list-item-group>
                        <div :id="provider.key"
                             v-for="(provider,index) in displayingProviders"
                             :key="provider.key">
                            <label v-if="checkUsablePaymentMethod(provider)">
                                <v-list-item
                                             :class="(provider.key === selectedProvider)?'selected':''">
                                    <v-list-item-action>
                                        <v-radio :value="provider.key"></v-radio>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>
                                            <span>{{
                                                    (provider.key === 'truemoney') ? 'True Money' : $t(provider.lang)
                                                }}</span>
                                            <template v-if="provider.detail">
                                                <div class="clear"></div>
                                                <div class="d-inline-block" style="margin-right: 0.5rem;">
                                                    <v-tooltip top v-if="provider.detail.fee_string" >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs"
                                                                    v-on="on"
                                                                    small
                                                                    label
                                                                    color="#eee">
                                                                + {{ $t('ค่าธรรมเนียม') }} {{ provider.detail.fee_string }}
                                                            </v-chip>
                                                        </template>
                                                        {{ $t('ราคาจะถูกเปลี่ยนขณะชำระเงินที่') }} {{provider.lang}}
                                                    </v-tooltip>
                                                </div>
                                            </template>
                                            <template v-if="provider.key === 'truemoney'">
                                                <div class="clear"></div>
                                                <v-chip href="https://blog.lnw.co.th/2023/02/15/truemoney/"
                                                        target="_blank"
                                                        small
                                                        label
                                                        color="#eee">
                                                    รับเงินคืนสูงสุด 30 บาท<u class="ml-2">คลิก</u>
                                                </v-chip>
                                            </template>
                                            <div v-if="myPaymentMethod.is_lnwpay_lite && !provider.is_lnwpay" class="d-inline-block">
                                                <v-chip v-bind="attrs"
                                                        v-on="on"
                                                        small
                                                        label
                                                        color="#eee">
                                                    {{ $t('ชำระเงินโดยตรงกับร้าน') }}
                                                </v-chip>
                                            </div>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <template v-if="provider.key == 'payatall'">
                                            <img :alt="provider.lang"
                                                 style="margin:0 10px 0 0; display: block"
                                                 :src="require('@/assets/payment/counter/7eleven.png')" />
                                        </template>
                                        <img :alt="provider.lang"
                                             style="margin:0;"
                                             :src="(provider.icon)?provider.icon:getImgUrl(provider)"/>
                                    </v-list-item-icon>
                                </v-list-item>
                            </label>
                            <v-divider v-if="index !== paymentMethods.length -1 && checkUsablePaymentMethod(provider)"></v-divider>
                        </div>
                    </v-list-item-group>
                </v-card>
            </v-radio-group>
        </PaymentProvidersWrapper>
    </div>
</template>
<script>
import MixinsPaymentComponent from "./MixinsPaymentComponent.vue";
import MixinsGetPaymentProviderImgUrl
    from "@/components/Order/components/PaymentMethod/components/LnwPaymentComponent/mixins/MixinsGetPaymentProviderImgUrl.vue";
import PaymentProvidersWrapper from "../components/PaymentProvidersWrapper.vue";
import {mapGetters} from "vuex";

export default {
    name: "MixinsOtherOnlinePaymentComponent",
    mixins: [MixinsPaymentComponent, MixinsGetPaymentProviderImgUrl],
    components: {PaymentProvidersWrapper},
    computed: {
        ...mapGetters({
            isSelectingPaymentMethodAndProvider: 'order/isSelectingPaymentMethodAndProvider',
        }),
        hint() {
            if (this.myPaymentMethod.hint) {
                return this.myPaymentMethod.hint;
            } else {
                return 'WARN: undefined payment_methods[i].hint';
            }
        },
        // ถ้าไม่ได้อยู่ในโหมดแก้ไข จะแสดงแค่ provider ที่เลือกอยู่
        displayingProviders() {
            if (this.value
                && this.value.selectedMethod
                && this.value[this.value.selectedMethod]
                && this.value[this.value.selectedMethod].provider_key
            ) {
                if (!this.isSelectingPaymentMethodAndProvider) {
                    return this.providers.filter(provider => provider.key === this.value[this.value.selectedMethod].provider_key);
                }
            }
            let displayProviders = [];
            this.providers.forEach(function (provider) {
                if (provider.enable) {
                    displayProviders.push(provider);
                }
            });
            if(this.myPaymentMethod.is_lnwpay_lite){
                displayProviders.sort((a, b) => {
                    return a.is_lnwpay - b.is_lnwpay;
                });
            }
            return displayProviders;
        },
    },
    methods: {
        getImgUrl(provider) {
            return this.getPaymentProviderImgUrl(this.paymentMethod, (provider.image_key) ? provider.image_key : provider.key, {provider});
        },
        checkUsablePaymentMethod(payment_method) {
            return (
                    payment_method.enable
                    && payment_method.key !== 'paysbuy_allcash'
                    && (!payment_method.minimum_amount || this.$store.state.order.orderData.price.pay >= payment_method.minimum_amount)
                    && (!payment_method.maximum_amount || this.$store.state.order.orderData.price.pay <= payment_method.maximum_amount)
                );
        },
    },
}
</script>
<style>
/*noinspection CssUnusedSymbol*/
.payment-method-provider-choice {
    border: 1px solid black;
    padding: 16px;
    cursor: pointer;
}

/*noinspection CssUnusedSymbol*/
.payment-method-provider-choice.payment-method-provider-choice-selected {
    border: 2px solid blue;
}
</style>